import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Url } from '@wix/da-url';
import {
  getCurrentUserHasCore,
  getIsVerificationNeeded,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { pushVerificationPopup } from '@wix/da-shared-react/pkg/VerificationPopup/actions';
import { useFirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/hooks';
import { FirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags';
import {
  getCanSubmitFreePrompts,
  getCanSubmitPaidPrompts,
} from '../../../redux/dreamup/selectors';
import {
  generateFromPrompt,
  pushPointsModal,
} from '../../../redux/dreamup/actions';
import { storeFormValuesForJoinPersistence } from '../../../redux/dreamupAnon/actions';
import { DreamupModals } from '../../../../constants';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';

export enum FormAction {
  DirectToPackagePicker = 'direct-to-package-picker',
  DisplayVerificationModal = 'display-verification-modal',
  DisplayPointsModal = 'display-points-modal',
  DisplayPromptGuidelinesModal = 'display-prompt-guidelines-modal',
  SubmitFreePrompt = 'submit-free-prompt',
  SubmitPaidPrompt = 'submit-paid-prompt',
  AnonJoin = 'anon-join',
  /**
   * This form action is only used as a backup in case there is a bug somewhere. We'll take some sensible
   * action on submission and hope for the best.
   */
  Fallback = 'fallback-action',
}

export function usePromptFormAction(): [
  (e?: React.FormEvent<HTMLFormElement>) => void,
  FormAction
] {
  const dispatch = useDispatch();
  const { isLoggedIn } = useContext(SessionContext);
  const hasCore = useSelector(getCurrentUserHasCore);
  const { hasFlag: hasNotSeenPromptGuidelines } = useFirstTimeFlag(
    FirstTimeFlag.IsDreamupPromptGuidelinesNotSeen
  );
  const isVerificationNeeded = useSelector(getIsVerificationNeeded);
  const canSubmitFreePrompts = useSelector(getCanSubmitFreePrompts);
  const canSubmitPaidPrompts = useSelector(getCanSubmitPaidPrompts);

  let formAction = FormAction.Fallback;

  if (!isLoggedIn) {
    formAction = FormAction.AnonJoin;
  } else if (isVerificationNeeded) {
    formAction = FormAction.DisplayVerificationModal;
  } else if (hasNotSeenPromptGuidelines) {
    formAction = FormAction.DisplayPromptGuidelinesModal;
  } else if (!canSubmitFreePrompts && hasCore && !canSubmitPaidPrompts) {
    formAction = FormAction.DisplayPointsModal;
  } else if (!canSubmitFreePrompts && !hasCore) {
    formAction = FormAction.DirectToPackagePicker;
  } else if (canSubmitFreePrompts) {
    formAction = FormAction.SubmitFreePrompt;
  } else if (canSubmitPaidPrompts) {
    formAction = FormAction.SubmitPaidPrompt;
  }

  const handleFormSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      const submitPrompt = (usePoints: boolean) => {
        dispatch(generateFromPrompt(usePoints));
      };

      switch (formAction) {
        case FormAction.AnonJoin:
          dispatch(storeFormValuesForJoinPersistence());
          break;
        case FormAction.DirectToPackagePicker:
          window.location.href = Url.coreMembershipLink(Url.dreamupLink());
          break;
        case FormAction.DisplayPointsModal:
          dispatch(pushPointsModal());
          break;
        case FormAction.DisplayVerificationModal:
          dispatch(pushVerificationPopup());
          break;
        case FormAction.DisplayPromptGuidelinesModal:
          dispatch(
            pushModal(DreamupModals.PROMPT_GUIDELINES, {
              wrapInModal: false,
              params: {
                // People in the prompt guidelines modal wil  all have free prompts left. They'll never
                // be able to a prompt without clearing that FTUE flag, so it should be safe to pass through
                // `false` for `usePoints`
                onConfirm: () => submitPrompt(false),
              },
            })
          );
          break;
        case FormAction.SubmitPaidPrompt:
          submitPrompt(true);
          break;
        case FormAction.SubmitFreePrompt:
          submitPrompt(false);
          break;
        // If our frontend checks don't work or there is a bug, we'll let the server tell us what we messed up.
        case FormAction.Fallback:
        default:
          submitPrompt(false);
          break;
      }
      e?.preventDefault();
    },
    [dispatch, formAction]
  );

  return [handleFormSubmit, formAction];
}
