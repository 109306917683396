import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './EmptyState.scss';

interface Props {
  className?: string;
}

const EmptyState: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={c(s['root'], className)}>
      <div className={s['heading-and-description']}>
        <Heading className={s['heading']}>
          {t('dreamup.emptyState.heading')}
        </Heading>
        <p className={s['description']}>
          {t('dreamup.emptyState.description')}
        </p>
      </div>
    </div>
  );
};

EmptyState.displayName = 'EmptyState';

export default EmptyState;
