import React from 'react';
import TextButton, {
  type TextButtonProps,
} from '@wix/da-ds/pkg/Buttons/TextButton';

interface Props extends Omit<TextButtonProps, 'size' | 'variant'> {}

const ActionBarTextButton: React.FC<Props> = props => {
  return <TextButton size="medium" variant="main" {...props} />;
};

ActionBarTextButton.displayName = 'ActionBarTextButton';

export default ActionBarTextButton;
