import React from 'react';
import { useSelector } from 'react-redux';
import { getCoreLevel } from '@wix/da-shared-react/pkg/publicSession/selectors';
import NonCoreStatus from '../NonCoreStatus';
import CoreProPlusStatus from '../CoreProPlusStatus';
import CoreProStatus from '../CoreProStatus';
import CorePlusStatus from '../CorePlusStatus';
import CoreStatus from '../CoreStatus';
import s from './AccountStatus.scss';

interface Props {}

const AccountStatus: React.FC<Props> = () => {
  const coreLevel = useSelector(getCoreLevel);

  let content: React.ReactNode;
  switch (coreLevel) {
    case 'core_access':
      content = <CoreStatus />;
      break;
    case 'core_plus':
      content = <CorePlusStatus />;
      break;
    case 'core_pro':
      content = <CoreProStatus />;
      break;
    case 'core_pro_plus':
      content = <CoreProPlusStatus />;
      break;
    default:
      content = <NonCoreStatus />;
      break;
  }

  return <div className={s['root']}>{content}</div>;
};

AccountStatus.displayName = 'AccountStatus';

export default AccountStatus;
