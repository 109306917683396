import React from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  getFreePromptsLimit,
  getFreePromptsRemaining,
} from '../../../redux/dreamup/selectors';
import s from './PromptCount.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

function usePromptCountText() {
  const { t } = useTranslation();
  const freePromptsRemaining = useSelector(getFreePromptsRemaining);
  const freePromptsLimit = useSelector(getFreePromptsLimit);
  const hasCore = useSelector(getCurrentUserHasCore);

  if (hasCore && freePromptsRemaining === 0) {
    return t('dreamup.header.freePromptCount.noPromptsRemaining.core');
  }

  if (freePromptsRemaining === 0) {
    return t('dreamup.header.freePromptCount.noPromptsRemaining.nonCore');
  }

  return t('dreamup.header.freePromptCount.hasPromptsRemaining', {
    count: freePromptsRemaining,
    limit: freePromptsLimit,
  });
}

const PromptCount: React.FC<Props> = ({ className }) => {
  const text = usePromptCountText();

  return <div className={c(s['root'], className)}>{text}</div>;
};

PromptCount.displayName = 'PromptCount';

export default PromptCount;
