import React from 'react';
import { useTranslation } from 'react-i18next';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { Url } from '@wix/da-url';
import s from './LegalSection.scss';

interface Props {}

interface BaseLegalLink {
  labelTranslationKey: string;
}

interface LegalLinkWithTranslationLink extends BaseLegalLink {
  hrefTranslationKey: string;
  href?: never;
}
interface LegalLinkWithHardcodedLink extends BaseLegalLink {
  href: string;
  hrefTranslationKey?: never;
}

const legalLinks: (
  | LegalLinkWithHardcodedLink
  | LegalLinkWithTranslationLink
)[] = [
  {
    hrefTranslationKey: 'dreamupAnon.legal.link1.url',
    labelTranslationKey: 'dreamupAnon.legal.link1.label',
  },
  {
    hrefTranslationKey: 'dreamupAnon.legal.link2.url',
    labelTranslationKey: 'dreamupAnon.legal.link2.label',
  },
  {
    hrefTranslationKey: 'dreamupAnon.legal.link3.url',
    labelTranslationKey: 'dreamupAnon.legal.link3.label',
  },
  {
    href: Url.coreMembershipLink(Url.dreamupLink()),
    labelTranslationKey: 'dreamupAnon.legal.link4.label',
  },
  {
    hrefTranslationKey: 'dreamupAnon.legal.link5.url',
    labelTranslationKey: 'dreamupAnon.legal.link5.label',
  },
];

const LegalSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div className={s['root']}>
      <p className={s['copyright']}>{t('dreamupAnon.legal.copyright')}</p>
      <div>
        {legalLinks.map((link, index) => (
          <>
            <PlainButtonOrLink
              key={index}
              href={link.href ?? t(link.hrefTranslationKey)}
              className={s['legal-link']}
              target="_blank"
            >
              {t(link.labelTranslationKey)}
            </PlainButtonOrLink>
            {index < legalLinks.length - 1 && (
              <span role="presentation" className={s['link-divider']}>
                {' '}
                |{' '}
              </span>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

LegalSection.displayName = 'LegalSection';

export default LegalSection;
