import React, { useContext } from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  getIsNegativePromptInputVisible,
  getVariationTarget,
} from '../../../../redux/dreamup/selectors';
import ImageSimilaritySliderField from './ImageSimilaritySliderField';
import ImageSimilaritySelectField from './ImageSimilaritySelectField';
import AspectRatioRadioField from './AspectRatioRadioField';
import AspectRatioSelectField from './AspectRatioSelectField';
import PromptStrengthSliderField from './PromptStrengthSliderField';
import PromptStrengthSelectField from './PromptStrengthSelectField';
import ArtStyleField from './ArtStyleField';
import NegativePromptToggle from './NegativePromptToggle';
import NegativePromptTextArea from './NegativePromptTextArea';
import s from './SettingsPanel.scss';

interface Props {
  className?: string;
}

const SettingsPanel: React.FC<Props> = ({ className }) => {
  const isMobile = useContext(MobileContext);
  const variationTarget = useSelector(getVariationTarget);
  const isNegativePromptInputVisible = useSelector(
    getIsNegativePromptInputVisible
  );

  return (
    <div className={c(s['root'], className)}>
      <ArtStyleField />
      {isMobile ? (
        <>
          <div className={s['mobile-aspect-ratio-and-prompt-strength-row']}>
            {!variationTarget && <AspectRatioSelectField />}
            {variationTarget && <ImageSimilaritySelectField />}
            <PromptStrengthSelectField />
          </div>
          <div className={s['mobile-negative-prompt-toggle-and-text-area']}>
            <NegativePromptToggle size="large" />

            {isNegativePromptInputVisible && (
              <NegativePromptTextArea minRows={2} maxRows={7} />
            )}
          </div>
        </>
      ) : (
        <>
          {!variationTarget && <AspectRatioRadioField />}
          {variationTarget && <ImageSimilaritySliderField />}
          <div className={s['slider-and-negative-prompt-toggle-row']}>
            <PromptStrengthSliderField />
            <NegativePromptToggle size="small" />
          </div>
          {isNegativePromptInputVisible && (
            <NegativePromptTextArea minRows={3} maxRows={5} />
          )}
        </>
      )}
    </div>
  );
};

SettingsPanel.displayName = 'SettingsPanel';

export default SettingsPanel;
