import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import Modal, { type ModalProps } from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import {
  PurchasePointsCardClickBiEvent,
  BiData,
} from '@wix/da-bi/pkg/events';
import PointsCard from '@wix/da-shared-react/pkg/PointsCard';
import { getPointsPackages } from '../../../redux/dreamup/selectors';
import PointsFooter from './PointsFooter';
import s from './PointsModal.scss';

interface Props
  extends Omit<
    ModalProps,
    'heading' | 'footerLeft' | 'footerRight' | 'footer' | 'isOpen'
  > {}

const PointsModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const pointsPackages = useSelector(getPointsPackages);

  return (
    <BiLoggerContextProvider value={{ component: 'points' }}>
      <Modal
        isOpen
        heading={
          isMobile
            ? t('dreamup.modal.points.heading.mobile')
            : t('dreamup.modal.points.heading')
        }
        footer={<PointsFooter />}
        {...props}
      >
        <div className={s['content-root']}>
          {pointsPackages.map((pointsPackage, index) => (
            <PointsCard
              key={index}
              pointsAmount={pointsPackage.points}
              price={pointsPackage.price}
              subproductId={pointsPackage.subproductId}
              biData={BiData<PurchasePointsCardClickBiEvent>({
                evid: 919,
                points_total: pointsPackage.points,
                subproductid: pointsPackage.subproductId,
              })}
            />
          ))}
        </div>
      </Modal>
    </BiLoggerContextProvider>
  );
};

PointsModal.displayName = 'PointsModal';

export default PointsModal;
