import React from 'react';
import c from 'classnames';
import { Trans } from 'react-i18next';
import { URLS } from '@wix/da-url';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import { BiData, type TopNavClickBiEvent } from '@wix/da-bi/pkg/events';
import s from './LegalUrlsBlock.scss';

interface Props {
  className?: string;
}

const LegalUrlsBlock: React.FC<Props> = ({ className }) => (
  <div className={c(s['root'], className)}>
    {/* prettier-ignore */}
    <Trans i18nKey="dreamup.sidebar.links.legal">
      <BiLink
        className="text-link-level-1-primary"
        href={URLS.TOS}
        target="_blank"
        rel="noreferrer"
        biData={BiData<TopNavClickBiEvent>({
          evid: 700,
          link_name: 'Terms of Service',
          link_url: URLS.TOS,
        })}
      >
        Terms of Service
      </BiLink>
      ,{' '}
      <BiLink
        className="text-link-level-1-primary"
        href={URLS.PRIVACY}
        target="_blank"
        rel="noreferrer"
        biData={BiData<TopNavClickBiEvent>({
          evid: 700,
          link_name: 'Privacy Policy',
          link_url: URLS.PRIVACY,
        })}
      >
        Privacy Policy
      </BiLink>
      , and{' '}
      <BiLink
        className="text-link-level-1-primary"
        href={URLS.DREAMUP_POLICY}
        target="_blank"
        rel="noreferrer"
        biData={BiData<TopNavClickBiEvent>({
          evid: 700,
          link_name: 'Dreamup Policy',
          link_url: URLS.DREAMUP_POLICY,
        })}
      >
        Dreamup Policy
      </BiLink>
    </Trans>
  </div>
);

LegalUrlsBlock.displayName = 'LegalUrlsBlock';

export default LegalUrlsBlock;
