import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiData, type TopNavClickBiEvent } from '@wix/da-bi/pkg/events';
import { Url } from '@wix/da-url';
import { ListItemLink } from '@wix/da-ds/pkg/List/ListItem';

interface Props {}

const ExploreCreationsLinkListItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <ListItemLink
      label={t('sidebar.links.dreamupExplore')}
      href={Url.tagsPageLink('dreamup')}
      biData={BiData<TopNavClickBiEvent>({
        evid: 700,
        link_name: t('sidebar.links.dreamupExplore'),
        link_url: Url.tagsPageLink('dreamup'),
      })}
    />
  );
};

ExploreCreationsLinkListItem.displayName = 'ExploreCreationsLinkListItem';

export default ExploreCreationsLinkListItem;
