import React from 'react';
import c from 'classnames';
import s from './SidebarDivider.scss';

interface Props {
  top: number;
  bottom: number;
  className?: string;
}

const SidebarDivider: React.FC<Props> = ({ top, bottom, className }) => (
  <div
    className={c(s['root'], className)}
    style={{ marginTop: `${top}px`, marginBottom: `${bottom}px` }}
  />
);

SidebarDivider.displayName = 'SidebarDivider';

export default SidebarDivider;
