import React from 'react';
import c from 'classnames';

import UserSymbol from '@wix/da-shared-react/pkg/User/_partials/UserSymbol/UserSymbol';
import s from './CoreRequirementIndicator.scss';

interface Props {
  className?: string;
}

const CoreRequirementIndicator: React.FC<Props> = ({ className }) => {
  return (
    <div className={c(s['root'], className)}>
      <div className={c(s['brace'], s['left-brace'])} />
      <UserSymbol userSymbol="core_pro" className={s['core-symbol']} />
      <UserSymbol userSymbol="core_pro_plus" className={s['core-symbol']} />
      <div className={c(s['brace'], s['right-brace'])} />
    </div>
  );
};

CoreRequirementIndicator.displayName = 'CoreRequirementIndicator';

export default CoreRequirementIndicator;
