import React, { useCallback } from 'react';
import Modal, { ModalProps } from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import { PapiDeviation } from '@wix/da-papi-types';
import MuroMini from '@wix/da-muro-ui/pkg/components/MuroEditor/preset/Mini';

interface Props
  extends Omit<ModalProps, 'showHeaderBorder' | 'isFullscreen' | 'heading'> {
  deviation: PapiDeviation;
}

const MuroModal: React.FC<Props> = ({
  deviation,
  onRequestClose,
  isOpen,
  ...rest
}) => {
  const handleClose = useCallback(() => {
    onRequestClose && onRequestClose(null as any);
  }, [onRequestClose]);

  return (
    <Modal
      heading={'Edit Dream'}
      // layoutClassName={s['layout']}
      isOpen={isOpen}
      showHeaderBorder
      isFullscreen
      onRequestClose={handleClose}
      {...rest}
    >
      <MuroMini deviation={deviation} />
    </Modal>
  );
};

MuroModal.displayName = 'MuroModal';

export default MuroModal;
