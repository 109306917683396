import { PapiDeviation } from '@wix/da-papi-types';
import { getPointsCount } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { createSelector } from 'reselect';
import { reduxKey } from './constants';
import { DreamupStoreSlice } from './types';
import { ThemeOptions, ThemeSkin } from '@wix/da-react-context/pkg/constants';

function getState(state: any): DreamupStoreSlice {
  if (!state[reduxKey]) {
    return {} as any;
  }
  return state[reduxKey];
}

export function getGenerationJobs(state) {
  return getState(state).generationJobs;
}

export function getFormValues(state) {
  return getState(state).formValues;
}

export function getPrompt(state) {
  return getState(state).formValues.prompt;
}

export function getIsPromptEmpty(state) {
  return getState(state).formValues.prompt.length === 0;
}

export function getSimilarity(state) {
  return getState(state).formValues.similarity;
}

export const getPromptStrength = createSelector(
  [getState],
  state => state.formValues.promptStrength
);

export const getIsNegativePromptInputVisible = createSelector(
  [getState],
  state => state.formValues.isNegativePromptInputVisible
);

export const getNegativePrompt = createSelector(
  [getState],
  state => state.formValues.negativePrompt
);

export function getAspectRatio(state) {
  return getState(state).formValues.aspectRatio;
}

export const getArtStyle = createSelector(
  [getState],
  state => state.formValues.artStyle
);

export const getDailyChallengeTag = createSelector(
  [getState],
  state => state.dailyChallengeTag
);

export function getCachedDeviation(
  state,
  id: number
): PapiDeviation | undefined {
  return getState(state).cachedExtendedDeviations[id];
}

export function getVariationTarget(state) {
  return getState(state).variationTarget;
}

export function getIsPromptSettingsPanelVisible(state) {
  return getState(state).isPromptSettingsPanelVisible;
}

export function getShouldPullFormValuesFromLocalStorage(state) {
  return getState(state).shouldPullFormValuesFromLocalStorage;
}

export function getCurrentSubmission(state) {
  return getState(state).currentSubmission;
}

export function getPointsPackages(state) {
  return getState(state).pointsPackages;
}

export function getRestrictions(state) {
  return getState(state).restrictions;
}

export function getFreePromptsLimit(state) {
  return getState(state).restrictions.freePromptsAllowed;
}

export function getFreePromptsRemaining(state) {
  return getRestrictions(state).freePromptsRemaining;
}

export function getHasFreePromptsRemaining(state) {
  return getFreePromptsRemaining(state) > 0;
}

export function getPricePerPrompt(state) {
  return getRestrictions(state).promptPointsPrice;
}

export function getImagesPerPrompt(state) {
  return getRestrictions(state).imagesPerPrompt;
}

export function getCanSubmitFreePrompts(state) {
  return (
    getRestrictions(state).canUseFreePrompt && getHasFreePromptsRemaining(state)
  );
}

export function getCanSubmitPaidPrompts(state) {
  return (
    getRestrictions(state).canUsePaidPrompt &&
    getPricePerPrompt(state) <= getPointsCount(state)
  );
}

export function getIsBurgerMenuOpen(state) {
  return getState(state).isBurgerMenuOpen;
}

export function getIsStartingImageSelectorOpen(state) {
  return getState(state).isStartingImageSelectorOpen;
}

export function forceDarkThemeSelector() {
  return ThemeOptions.DARK;
}

export function forceDarkThemeSkinSelector() {
  return ThemeSkin.NONE;
}
