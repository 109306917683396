import React from 'react';
import c from 'classnames';
import HeadlessRadioButton, {
  RadioButtonProps as HeadlessRadioButtonProps,
} from '@wix/da-a11y/pkg/components/formControls/RadioButton';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import DreamupIcon from '@wix/da-ds/pkg/Icons/32x32/DreamUp';
import s from './ImageRadioButton.scss';

type Props = HeadlessRadioButtonProps & {
  backgroundImage: React.CSSProperties['backgroundImage'];
  showDreamupLogo?: boolean;
};

/**
 * A radio button in the shape of a lozenge.
 *
 * This component has no theme, so be careful where you use it. Be sure to check contrast values
 * for accessibility compliance.
 */
const ImageRadioButton: React.FC<Props> = ({
  checked,
  className,
  children,
  backgroundImage,
  showDreamupLogo,
  ...rest
}) => {
  return (
    <HeadlessRadioButton
      checked={checked}
      className={s['headless-wrapper']}
      {...rest}
    >
      <div
        className={c(s['root'], checked && s['selected'], className)}
        style={{ backgroundImage }}
      >
        {showDreamupLogo && (
          <DreamupIcon size={IconSize.LARGE} className={s['dreamup-logo']} />
        )}
        <div className={s['label']}>{children}</div>
      </div>
    </HeadlessRadioButton>
  );
};

ImageRadioButton.displayName = 'ImageRadioButton';

export default ImageRadioButton;
