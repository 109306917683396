import React, { InputHTMLAttributes } from 'react';
import c from 'classnames';
import s from './RangeSlider.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  min: number;
  max: number;
  step: number;
  displayValue?: React.ReactNode;
}

const RangeSlider: React.FC<Props> = ({
  value,
  displayValue = value,
  ...rest
}) => {
  return (
    <div className={s['slider-and-percent']}>
      <input
        className={c(s['slider'], s['dreamup-branding'])}
        type="range"
        value={value}
        {...rest}
      />
      <div aria-hidden={true} className={s['percent']}>
        {displayValue}
      </div>
    </div>
  );
};

RangeSlider.displayName = 'RangeSlider';

export default RangeSlider;
