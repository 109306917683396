import { all, put, takeEvery, select } from '@redux-saga/core/effects';
import { initializeIfNeeded } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { normalizeDeviations } from '@wix/da-shared-react/pkg/redux/normalizr/helpers';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import {
  insertItemsAtOffsets,
  deleteItems,
  resetStream,
} from '@wix/da-shared-react/pkg/Stream/withOffset/actions';
import {
  initializeDeviationsInFolder,
  initializeFoldersInFolder,
  fetchNextDeviations,
  fetchNextFolders,
  fileUploaded,
} from './actions';
import {
  deviationStreamConfig,
  folderStreamConfig,
  getDeviationStreamId,
  getFolderStreamId,
} from './helpers';
import type {
  PapiDaSharedGallectionContents,
  PapiDaSharedGallectionFolders,
  PapiRequestDaSharedGallectionContents,
  PapiRequestDaSharedGallectionFolders,
} from '@wix/da-papi-types';
import { getNextOffset } from '@wix/da-shared-react/pkg/Stream/withOffset/selectors';
import { getItemsPerFetch } from '@wix/da-shared-react/pkg/Stream/base/selectors';
import {
  takeStreamFetch,
  fetchStreamContents,
} from '@wix/da-shared-react/pkg/Stream/helpers';
import { getCurrentUser } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getCurrentFolder } from './selectors';
import { deleteDeviationSuccess } from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';

function* handleInitializeDeviationsInFolder({
  payload: { parentFolder, hasMore, nextOffset, results },
}: ReturnType<typeof initializeDeviationsInFolder>) {
  const { entities, result: normalizedDeviationIds } = normalizeDeviations(
    results ?? []
  );

  const streamConfig: StreamInitConfig = {
    ...deviationStreamConfig,
    autoFetch: false,
    hasMore,
    nextOffset: nextOffset ?? undefined,
    items: normalizedDeviationIds,
    entities,
  };

  yield put(
    initializeIfNeeded(
      getDeviationStreamId(parentFolder.folderId),
      streamConfig
    )
  );
}

function* handleInitializeFoldersInFolder({
  payload: { parentFolder, hasMore, nextOffset, results },
}: ReturnType<typeof initializeFoldersInFolder>) {
  const streamConfig: StreamInitConfig = {
    ...folderStreamConfig,
    autoFetch: false,
    hasMore,
    nextOffset: nextOffset ?? undefined,
    items: results,
  };

  yield put(
    initializeIfNeeded(getFolderStreamId(parentFolder.folderId), streamConfig)
  );
}

function* handleFetchNextDeviations({
  payload: { streamId },
}: ReturnType<typeof fetchNextDeviations>) {
  const { offset, limit, folderId, user } = yield select(state => ({
    offset: getNextOffset(state, streamId),
    limit: getItemsPerFetch(state, streamId),
    folderId: getCurrentFolder(state).folderId,
    user: getCurrentUser(state),
  }));

  yield fetchStreamContents<
    PapiRequestDaSharedGallectionContents,
    PapiDaSharedGallectionContents
  >({
    streamId,
    params: {
      type: 'stash',
      username: user?.username,
      folderid: folderId,
      offset: offset,
      limit: limit,
    },
    url: 'gallection/contents',
    onSuccess: function* (result) {
      const { entities } = normalizeDeviations(result.results);
      yield put(putEntities({ entities }));
    },
    getStreamData: result => {
      const { result: normalizedDeviationIds } = normalizeDeviations(
        result.results
      );
      return {
        hasMore: !!result.hasMore,
        items: normalizedDeviationIds,
        nextOffset: result.nextOffset ?? undefined,
      };
    },
  });
}

function* handleFetchNextFolders({
  payload: { streamId },
}: ReturnType<typeof fetchNextFolders>) {
  const { offset, limit } = yield select(state => ({
    offset: getNextOffset(state, streamId),
    limit: getItemsPerFetch(state, streamId),
  }));

  yield fetchStreamContents<
    PapiRequestDaSharedGallectionFolders,
    PapiDaSharedGallectionFolders
  >({
    streamId,
    params: {
      type: 'stash',
      offset: offset,
      limit: limit,
    },
    url: 'gallection/folders',
    getStreamData: result => ({
      hasMore: !!result.hasMore,
      items: result.results,
      nextOffset: result.nextOffset ?? undefined,
    }),
  });
}

function* handleFileUploaded({
  payload: { deviation },
}: ReturnType<typeof fileUploaded>) {
  const currentFolder: ReturnType<typeof getCurrentFolder> = yield select(
    getCurrentFolder
  );
  const streamId = getDeviationStreamId(currentFolder.folderId);

  if (!deviation) {
    // dunno what this thing was, just start over
    yield put(resetStream(streamId));
    return;
  }

  yield put(
    insertItemsAtOffsets({
      streamId,
      newItems: [{ item: deviation, offset: 0 }],
    })
  );
}

function* handleFileDeleted({
  payload: { deviation },
}: ReturnType<typeof deleteDeviationSuccess>) {
  const currentFolder: ReturnType<typeof getCurrentFolder> = yield select(
    getCurrentFolder
  );
  const streamId = getDeviationStreamId(currentFolder.folderId);
  const { result } = normalizeDeviations([deviation]);

  yield put(
    deleteItems({
      streamId,
      items: result,
    })
  );
}

export default function* saga() {
  yield all([
    takeEvery(initializeDeviationsInFolder, handleInitializeDeviationsInFolder),
    takeEvery(initializeFoldersInFolder, handleInitializeFoldersInFolder),
    takeStreamFetch(fetchNextDeviations, handleFetchNextDeviations),
    takeStreamFetch(fetchNextFolders, handleFetchNextFolders),
    takeEvery(fileUploaded, handleFileUploaded),
    takeEvery(deleteDeviationSuccess, handleFileDeleted),
  ]);
}
