import React from 'react';
import {
  ListItemButton,
  ListItemButtonProps,
} from '@wix/da-ds/pkg/List/ListItem';
import {
  IconSize,
  type Props as IconProps,
} from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './ActionPanelItem.scss';

interface Props
  extends Omit<
    ListItemButtonProps,
    'prefix' | 'isLabelBold' | 'fillContainerWidth'
  > {
  icon: React.ComponentType<IconProps>;
}

const ActionPanelItem: React.FC<Props> = ({
  icon: IconComponent,
  label,
  ...rest
}) => {
  return (
    <ListItemButton
      label={label}
      isLabelBold
      compact
      fillContainerWidth
      prefix={
        <IconComponent
          className={s['icon']}
          size={IconSize.MEDIUM}
          disableDefaultColors
        />
      }
      {...rest}
    />
  );
};

ActionPanelItem.displayName = 'ActionPanelItem';

export default ActionPanelItem;
