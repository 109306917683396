import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { StreamType } from '@wix/da-shared-react/pkg/Stream';
import { fetchNextDeviations, fetchNextFolders } from './actions';

export const deviationStreamConfig: StreamInitConfig = {
  streamType: StreamType.WITH_OFFSET,
  autoFetch: true,
  itemsPerFetch: 24,
  fetchNextCallback: fetchNextDeviations,
};

export const folderStreamConfig: StreamInitConfig = {
  streamType: StreamType.WITH_OFFSET,
  autoFetch: true,
  itemsPerFetch: 50,
  fetchNextCallback: fetchNextFolders,
};

export function getDeviationStreamId(parentFolderId: number) {
  return `deviations-${parentFolderId}`;
}
export function getFolderStreamId(parentFolderId: number) {
  return `folders-${parentFolderId}`;
}
