import { createAction } from 'redux-actions';
import type { PapiDeviation, PapiGallection } from '@wix/da-papi-types';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';

export const initializeDeviationsInFolder = createAction(
  'INIT_DEVIATIONS_IN_FOLDER',
  (streamData: {
    parentFolder: PapiGallection;
    hasMore?: boolean;
    nextOffset?: number | null;
    results?: PapiDeviation[];
    autoFetch?: boolean;
  }) => streamData
);

export const initializeFoldersInFolder = createAction(
  'INIT_FOLDERS_IN_FOLDER',
  (streamData: {
    parentFolder: PapiGallection;
    hasMore?: boolean;
    nextOffset?: number | null;
    results?: PapiGallection[];
  }) => streamData
);

export const fetchNextDeviations = createCallbackActionCreator(
  'FETCH_NEXT_DEVIATIONS'
);
export const fetchNextFolders =
  createCallbackActionCreator('FETCH_NEXT_FOLDERS');

export const changeFolder = createAction(
  'CHANGE_FOLDER',
  (folder: PapiGallection) => ({ folder })
);

export const fileUploaded = createAction(
  'FILE_UPLOADED',
  ({ deviation }: { deviation?: PapiDeviation }) => ({ deviation })
);
