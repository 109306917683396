import { handleActions } from 'redux-actions';
import { reduxKey } from './constants';
import { DreamupAnonStoreSlice } from './types';
import { initializeAnonLanding } from './actions';

const reducers = handleActions<DreamupAnonStoreSlice, any>(
  {
    [`${initializeAnonLanding}`]: (
      state,
      action: ReturnType<typeof initializeAnonLanding>
    ) => ({ ...state, faqs: action.payload.faqs }),
  },
  {
    faqs: [],
  }
);

export default {
  [reduxKey]: reducers,
};
