import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { getIsStartingImageSelectorOpen } from '../../../redux/dreamup/selectors';
import { usePromptFormAction } from './usePromptFormAction';
import PromptInputDesktop from './PromptInputV2/dsPresets/PromptInputDesktop';
import PromptInputMobile from './PromptInputV2/dsPresets/PromptInputMobile';
import StartingImageSelector from './StartingImageSelector';
import s from './PromptForm.scss';

interface Props {}

const PromptForm: React.FC<Props> = () => {
  const isMobile = useContext(MobileContext);
  const isStartingImageSelectorOpen = useSelector(
    getIsStartingImageSelectorOpen
  );
  const [handleSubmit, formAction] = usePromptFormAction();

  return (
    <>
      <div className={s['root']}>
        <form onSubmit={handleSubmit}>
          {isMobile ? (
            <PromptInputMobile formAction={formAction} />
          ) : (
            <PromptInputDesktop formAction={formAction} />
          )}
        </form>
      </div>
      <StartingImageSelector isOpen={isStartingImageSelectorOpen} />
    </>
  );
};

PromptForm.displayName = 'PromptForm';

export default PromptForm;
