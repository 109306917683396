import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import { Props } from './DreamupApp';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';

type StateProps = Pick<Props, 'environment'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  environment: getEnv(state).type,
});

export default connect(mapStateToProps);
