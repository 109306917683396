import { all, select, takeLatest } from 'redux-saga/effects';
import {
  initializeAnonLanding,
  storeFormValuesForJoinPersistence,
} from './actions';
import { put } from '@redux-saga/core/effects';
import { StreamType } from '@wix/da-shared-react/pkg/Stream';
import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { initializeIfNeeded } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { setCompliantLocalStorageItem } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/actions';
import { CompliantLocalStorageKeys } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/constants';
import { ANON_LANDING_DEVIATION_STREAM_ID } from '../dreamup/constants';
import { Url } from '@wix/da-url';
import { JoinPersistentFormValues } from './types';
import { getArtStyle, getPrompt } from '../dreamup/selectors';

function* handleInitializeAnonLanding(
  action: ReturnType<typeof initializeAnonLanding>
) {
  const streamConfig: StreamInitConfig = {
    streamType: StreamType.WITH_OFFSET,
    autoFetch: false,
    itemsPerFetch: 25,
    items: action.payload.deviations,
  };

  yield put(initializeIfNeeded(ANON_LANDING_DEVIATION_STREAM_ID, streamConfig));
}

function* handleStoreFormValuesForJoinPersistence() {
  const artStyle = yield select(getArtStyle);
  const prompt = yield select(getPrompt);

  const storageValue: JoinPersistentFormValues = {
    artStyle,
    prompt,
  };

  yield put(
    setCompliantLocalStorageItem({
      key: CompliantLocalStorageKeys.DREAMUP_ANON_FORM,
      value: JSON.stringify(storageValue),
    })
  );

  if (typeof window !== 'undefined') {
    window.location.href = Url.joinLink(
      undefined,
      Url.dreamupLink({ persistFromStorage: true }),
      undefined,
      { daDreamup: true }
    );
  }
}

export function* dreamupAnonSaga() {
  yield all([
    takeLatest(initializeAnonLanding, handleInitializeAnonLanding),
    takeLatest(
      storeFormValuesForJoinPersistence,
      handleStoreFormValuesForJoinPersistence
    ),
  ]);
}
