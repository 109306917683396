import React from 'react';
import c from 'classnames';
import s from './ButtonBorder.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ButtonBorder: React.FC<Props> = ({ children, className }) => {
  return <div className={c(s['root'], className)}>{children}</div>;
};

ButtonBorder.displayName = 'ButtonBorder';

export default ButtonBorder;
