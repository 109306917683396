import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Header from '../Header';
import Content from '../Content';
import SiteHeader from './SiteHeader';
import Rail from './Rail';
import OldPage from './OldPage';
import s from './Page.scss';

interface Props {}

const Page: React.FC<Props> = () => {
  const isMobile = useContext(MobileContext);
  return isMobile ? (
    <OldPage />
  ) : (
    <div className={s['root']}>
      <SiteHeader className={s['site-header']} />
      <div className={s['content-and-rail']}>
        <div className={s['content-wrapper']} data-testid="app-content">
          <Header />
          <Content className={s['deviations-area']} />
        </div>
        {!isMobile && <Rail className={s['rail']} />}
      </div>
    </div>
  );
};

Page.displayName = 'Page';

export default Page;
