import React from 'react';
import c from 'classnames';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './AccountStatusHeading.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AccountStatusHeading: React.FC<Props> = ({ children, className }) => (
  <Heading className={c(s['root'], className)}>{children}</Heading>
);

AccountStatusHeading.displayName = 'AccountStatusHeading';

export default AccountStatusHeading;
