import React from 'react';
import SimpleSiteHeader from './SimpleSiteHeader';
import CallToActionSection from './CallToActionSection';
import FaqSection from './FaqSection';
import ArtistControlSection from './ArtistControlSection';
import HowToSection from './HowToSection';
import FormSection from './FormSection';
import ArtSection from './ArtSection';
import LegalSection from './LegalSection';

interface Props {}

const AnonLandingPage: React.FC<Props> = () => {
  return (
    <main>
      <SimpleSiteHeader />
      <FormSection />
      <ArtSection />
      <HowToSection />
      <ArtistControlSection />
      <FaqSection />
      <CallToActionSection />
      <LegalSection />
    </main>
  );
};

AnonLandingPage.displayName = 'AnonLandingPage';

export default AnonLandingPage;
