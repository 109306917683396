import React from 'react';
import IconButton, {
  type Props as IconButtonProps,
} from '@wix/da-ds/pkg/Buttons/IconButton';

interface Props extends Omit<IconButtonProps, 'size' | 'variant'> {}

const ActionBarIconButton: React.FC<Props> = props => {
  return <IconButton size="medium" variant="main" {...props} />;
};

ActionBarIconButton.displayName = 'ActionBarIconButton';

export default ActionBarIconButton;
