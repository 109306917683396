import React from 'react';
import { SubmissionStatus } from '../../../../../../redux/dreamup/types';
import PromptError from '../../partials/PromptError';
import { useSelector } from 'react-redux';
import { getCurrentSubmission } from '../../../../../../redux/dreamup/selectors';

interface Props {
  className?: string;
}

const ErrorArea: React.FC<Props> = ({ className }) => {
  const currentSubmission = useSelector(getCurrentSubmission);

  return (
    <div aria-live="polite" className={className}>
      {currentSubmission.status === SubmissionStatus.Error && <PromptError />}
    </div>
  );
};

ErrorArea.displayName = 'ErrorArea';

export default ErrorArea;
