import React from 'react';
import type { PapiDeviation } from '@wix/da-papi-types';
import { getDreamupPrompt } from '@wix/da-shared-react/pkg/types/deviation/getters';
import ScrollablePrompt from '../_partials/ScrollablePrompt';
import DetailBlock from '../_partials/DetailBlock';
import MeasuredArtView from '../_partials/MeasuredArtView';
import SubmitButton from '../_partials/SubmitButton';
import ActionsPanel from './ActionsPanel';
import s from './MobileContent.scss';

interface Props {
  deviation: PapiDeviation;
}

const MobileContent: React.FC<Props> = ({ deviation }) => {
  return (
    <div className={s['root']}>
      <MeasuredArtView deviation={deviation} className={s['image']} />
      <div className={s['info-area']}>
        <SubmitButton deviation={deviation} />
        <ActionsPanel deviation={deviation} />
        <ScrollablePrompt value={getDreamupPrompt(deviation)} />
        <DetailBlock deviation={deviation} orientation="vertical" />
      </div>
    </div>
  );
};

MobileContent.displayName = 'MobileContent';

export default MobileContent;
