import React, { MouseEventHandler } from 'react';
import c from 'classnames';
import IconButton, {
  type Props as IconButtonProps,
} from '@wix/da-ds/pkg/Buttons/IconButton';
import ArrowRightIcon from '@wix/da-ds/pkg/Icons/16x16/ArrowRight';
import ArrowLeftIcon from '@wix/da-ds/pkg/Icons/16x16/ArrowLeft';
import s from './SliderArrowButton.scss';

interface Props
  extends Omit<
    IconButtonProps,
    'size' | 'variant' | 'icon' | 'onClick' | 'type'
  > {
  direction: 'left' | 'right';
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const SliderArrowButton: React.FC<Props> = ({
  direction,
  onClick,
  className,
  ...rest
}) => {
  return (
    <IconButton
      size="small"
      variant="main"
      icon={direction === 'left' ? ArrowLeftIcon : ArrowRightIcon}
      onClick={
        onClick as MouseEventHandler<HTMLButtonElement> &
          MouseEventHandler<HTMLAnchorElement>
      }
      type="button"
      className={c(s['root'], className)}
      {...rest}
    />
  );
};

SliderArrowButton.displayName = 'SliderArrowButton';

export default SliderArrowButton;
