import React, { type HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import ErrorIcon from '@wix/da-ds/pkg/Icons/24x24/Error';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { GenerationError } from '../../../redux/dreamup/types';
import s from './DreamupErrorThumb.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: number;
  height?: number;
  errorCode?: GenerationError;
}

function useErrorMessage(errorCode: GenerationError | undefined) {
  const { t } = useTranslation();

  switch (errorCode) {
    case GenerationError.TosViolation:
      return t('dreamup.blockedThumb.error.tos.description');
    default:
      return t('dreamup.blockedThumb.error.generic.description');
  }
}

const DreamupErrorThumb: React.FC<Props> = ({
  errorCode,
  className,
  width,
  height,
  style = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const errorMessage = useErrorMessage(errorCode);
  return (
    <Section
      className={c(s['root'], className)}
      style={{ width, height, ...style }}
      {...rest}
    >
      <ErrorIcon
        size={IconSize.MEDIUM}
        className={s['icon']}
        role="presentation"
        disableDefaultColors
      />
      <Heading className={s['heading']}>
        {t('dreamup.blockedThumb.error.heading')}
      </Heading>
      <p className={s['description']}>{errorMessage}</p>
    </Section>
  );
};

DreamupErrorThumb.displayName = 'DreamupErrorThumb';

export default DreamupErrorThumb;
