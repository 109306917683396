/* eslint-disable @wix/da/import-scss-last */
import 'react-hot-loader/patch';
import React from 'react';
import { AppContainer } from 'react-hot-loader';
import { getClientInstance } from '@wix/da-ddt';
import i18n from '@wix/da-i18next/pkg/presetI18nClientConfig';
// project-base needs to go before app styles
import '@wix/da-ds/src/styles/project-base.scss';
import DreamupApp from './components/dreamup/DreamupApp';
import { configureStore } from './redux/dreamup/configureStore';
import { configureHistoryListener } from '@wix/da-shared-react/pkg/redux/routing/history';
import { hydrateRoot } from 'react-dom/client';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import AppDocumentWrapper from '@wix/da-react-app/pkg/client/AppDocumentWrapper';
import Modal from 'react-modal';
import {
  forceDarkThemeSelector,
  forceDarkThemeSkinSelector,
} from './redux/dreamup/selectors';

const ddt = getClientInstance();
ddt.replayServerSideLogs(window['__DDT__']);

const initialI18n = window['__INITIAL_I18N__'];

const initialState = window['__INITIAL_STATE__'];
delete window['__INITIAL_STATE__'];

const store = configureStore(initialState);
configureHistoryListener(store);

i18n(
  initialI18n,
  getEnv(store.getState()).isDebug,
  getEnv(store.getState()).flags
);
Modal.setAppElement('body');
const root = hydrateRoot(
  document,
  <AppDocumentWrapper
    store={store}
    themeSelector={forceDarkThemeSelector}
    themeSkinSelector={forceDarkThemeSkinSelector}
  >
    <DreamupApp language={initialI18n.locale} />
  </AppDocumentWrapper>
);

if (module.hot) {
  module.hot.accept('./components/dreamup/DreamupApp', () => {
    // eslint-disable-next-line global-require
    const AppLatest = require('./components/dreamup/DreamupApp').default;
    root.render(
      <AppContainer>
        <AppDocumentWrapper store={store}>
          <AppLatest language={initialI18n.locale} />
        </AppDocumentWrapper>
      </AppContainer>
    );
  });
}
