import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import LabelText from '../LabelText';
import PanelInputField from '../PanelInputField';

import ArtStyleSlider from './ArtStyleSlider';

interface Props {}

const ArtStyleField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const groupLabelId = useUniqueId();

  return (
    <PanelInputField
      rootTag="div"
      role="radiogroup"
      aria-labelledby={groupLabelId}
      label={
        <LabelText id={groupLabelId}>
          {t('dreamup.header.settingsPanel.artStyle.label')}
        </LabelText>
      }
      input={<ArtStyleSlider />}
    />
  );
};

ArtStyleField.displayName = 'ArtStyleField';

export default ArtStyleField;
