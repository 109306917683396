import React from 'react';
import c from 'classnames';
import s from './HowToEntry.scss';

interface Props {
  label: React.ReactNode;
  sublabel: React.ReactNode;
  className?: string;
}

const HowToEntry: React.FC<Props> = ({ label, sublabel, className }) => {
  return (
    <li className={c(s['root'], className)}>
      <div className={s['label-and-sublabel']}>
        <span className={s['label']}>{label}</span>
        <span className={s['sublabel']}>{sublabel}</span>
      </div>
    </li>
  );
};

HowToEntry.displayName = 'HowToEntry';

export default HowToEntry;
