import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import Trashcan from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import Close from '@wix/da-ds/pkg/Icons/16x16/Close';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import Dumbo from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import { clearVariation } from '../../../../../../redux/dreamup/actions';
import ButtonBorder from '../../partials/ButtonBorder';
import s from './VariationTag.scss';

interface Props {
  deviation: PapiDeviation;
  showRemoveIcon?: boolean;
}

const VariationTag: React.FC<Props> = ({ deviation, showRemoveIcon }) => {
  const { t } = useTranslation();

  const dispatchClearVariation = useAction(clearVariation);

  const handleClick = useCallback(() => {
    dispatchClearVariation();
  }, [dispatchClearVariation]);

  return (
    <ButtonBorder className={s['root']}>
      <PlainButtonOrLink
        className={c(s['button'], showRemoveIcon && s['has-remove-icon'])}
        onClick={handleClick}
        aria-label={t('dreamup.header.input.variationTag.a11y.label')}
        type="button"
      >
        <Dumbo deviation={deviation} width={24} height={24} useCoverMode />
        {showRemoveIcon && (
          <Close size={IconSize.SMALLER} disableDefaultColors />
        )}
        {!showRemoveIcon && (
          <div className={s['remove-overlay']} aria-hidden="true">
            <Trashcan size={IconSize.MEDIUM} disableDefaultColors />
          </div>
        )}
      </PlainButtonOrLink>
    </ButtonBorder>
  );
};

VariationTag.displayName = 'VariationTag';

export default VariationTag;
