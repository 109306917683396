import React from 'react';
import IconButtonWithContextMenuV2, {
  type IconButtonWithContextMenuProps,
} from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenuV2';

interface Props
  extends Omit<IconButtonWithContextMenuProps, 'size' | 'variant'> {}

const ActionBarIconButtonWithContextMenu: React.FC<Props> = props => {
  return (
    <IconButtonWithContextMenuV2 size="medium" variant="main" {...props} />
  );
};

ActionBarIconButtonWithContextMenu.displayName =
  'ActionBarIconButtonWithContextMenu';

export default ActionBarIconButtonWithContextMenu;
