import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { Aside } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import DevpageSidebarPlacement from '@wix/da-shared-react/pkg/DealerAd/Placement/DevpageSidebar';
import AccountStatus from '../../../shared/accountStatus/AccountStatus';
import OldDreamupLogoBlock from '../../Sidebar/OldDreamupLogoBlock';
import s from './Rail.scss';

interface Props {
  className?: string;
}

const Rail: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Aside className={c(s['root'], className)}>
      <div className={s['sticky-wrapper']}>
        <div className={s['grid']}>
          <VisuallyHidden>
            <Heading>{t('dreamup.rail.a11y.heading')}</Heading>
          </VisuallyHidden>
          <AccountStatus />

          <DevpageSidebarPlacement />
        </div>

        <OldDreamupLogoBlock className={s['old-dreamup-logo']} />
      </div>
    </Aside>
  );
};

Rail.displayName = 'Rail';

export default Rail;
