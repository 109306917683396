import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import CopyIcon from '@wix/da-ds/pkg/Icons/16x16/Copy';
import { useCopyToClipboard } from '@wix/da-shared-react/pkg/utils/hooks/useCopyToClipboard';
import s from './ScrollablePrompt.scss';

interface Props {
  value: string | undefined;
}

const ScrollablePrompt: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  const copyPromptToClipboard = useCopyToClipboard(value ?? '');

  return (
    <div className={s['root']}>
      <div className={s['scrollable-root']}>{value}</div>
      <IconButton
        className={s['copy-button']}
        size="small"
        icon={CopyIcon}
        tooltipText={t(
          'dreamup.modal.promptInfo.actions.promptCopyButton.tooltip'
        )}
        aria-label={t(
          'dreamup.modal.promptInfo.actions.promptCopyButton.tooltip'
        )}
        onClick={copyPromptToClipboard}
      />
    </div>
  );
};

ScrollablePrompt.displayName = 'ScrollablePrompt';

export default ScrollablePrompt;
