import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type { PapiDeviation } from '@wix/da-papi-types';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import { DATE_FORMATS, formatDate } from '@wix/da-ds/pkg/Time/functions';
import Detail from '../Detail';
import s from './DetailBlock.scss';

interface Props {
  deviation: PapiDeviation;
  orientation: 'vertical' | 'horizontal';
}

/**
 * A wrapping row of unrelated deviation details
 */
const DetailBlock: React.FC<Props> = ({ deviation, orientation }) => {
  const { t } = useTranslation();

  const file = deviation.extended?.originalFile;
  return (
    <Section className={c(s['root'], s[orientation])}>
      <VisuallyHidden>
        <Heading>{t('dreamup.modal.promptInfo.details.a11y.heading')}</Heading>
      </VisuallyHidden>
      {deviation.publishedTime && (
        <Detail
          className={s['detail']}
          label={t('dreamup.modal.promptInfo.details.creationDate.label')}
          value={formatDate(deviation.publishedTime, DATE_FORMATS.Date.Full)}
        />
      )}
      {file && (
        <Detail
          className={s['detail']}
          label={t('dreamup.modal.promptInfo.details.imageSize.label')}
          value={`${file.width} × ${file.height}`}
        />
      )}
    </Section>
  );
};

DetailBlock.displayName = 'DetailBlock';

export default DetailBlock;
