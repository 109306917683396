import React from 'react';
import {
  ThemeOverride,
  ThemeOptions,
} from '@wix/da-react-context/pkg/ThemeContext';
import VariationTag from '../../lego/VariationTag';
import StartingImageButton from '../../lego/StartingImageButton';
import PromptTextArea from '../../lego/PromptTextArea';
import PromptSubmitButton from '../../lego/PromptSubmitButton';
import { useSelector } from 'react-redux';
import { getVariationTarget } from '../../../../../../redux/dreamup/selectors';
import { FormAction } from '../../../usePromptFormAction';
import PromptCount from '../../../../PromptCount';
import SettingsPanel from '../../../SettingsPanel';
import ErrorArea from '../../lego/ErrorArea';
import s from './PromptInputMobile.scss';

interface Props {
  formAction: FormAction;
}

const PromptInputMobile: React.FC<Props> = ({ formAction }) => {
  const variationTarget = useSelector(getVariationTarget);
  return (
    <div className={s['root']}>
      <div className={s['count-and-variation-tag']}>
        <PromptCount />
        {variationTarget ? (
          <VariationTag deviation={variationTarget} showRemoveIcon />
        ) : (
          <StartingImageButton />
        )}
      </div>
      <ThemeOverride theme={ThemeOptions.LIGHT}>
        <PromptTextArea formAction={formAction} minRows={2} />
      </ThemeOverride>
      <SettingsPanel />
      <PromptSubmitButton formAction={formAction} />
      <ErrorArea />
    </div>
  );
};

PromptInputMobile.displayName = 'PromptInputMobile';

export default PromptInputMobile;
