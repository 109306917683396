import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type { PapiTag } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { BiData, TagClickBiEvent } from '@wix/da-bi/pkg/events';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import DailyChallengeIcon from '@wix/da-ds/pkg/Icons/16x16/DailyChallenge';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './DailyChallengeBanner.scss';

interface Props {
  tag: PapiTag;
  className?: string;
}

const DailyChallengeBanner: React.FC<Props> = ({ tag, className }) => {
  const { t } = useTranslation();

  return (
    <aside className={c(s['root'], className)}>
      <DailyChallengeIcon
        size={IconSize.SMALLER}
        disableDefaultColors
        aria-hidden="true"
      />
      <Heading>
        {t('dreamup.dailyChallenges.banner.text', {
          postProcess: 'reactPostprocessor',
          tag: (
            <TextButton
              variant="main"
              className={s['tag-link']}
              size="small"
              href={Url.dailyChallengesLink()}
              biData={BiData<TagClickBiEvent>({
                evid: 391,
                tag_text: tag.tag,
                link_url: Url.dailyChallengesLink(),
              })}
            >
              #{tag.tag}
            </TextButton>
          ),
        })}
      </Heading>
    </aside>
  );
};

DailyChallengeBanner.displayName = 'DailyChallengeBanner';

export default DailyChallengeBanner;
