import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import Grid from '@wix/da-shared-react/pkg/Grid/StandardGrid';
import { useNormalizedDeviationStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { GridElement } from '@wix/da-shared-react/pkg/Grid/types';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { default as OnScrollBottom } from '@wix/da-shared-react/pkg/OnScrollBottom/OnScrollBottom';
import { DISTANCE_UNIT } from '@wix/da-shared-react/pkg/OnScrollBottom';
import {
  deviationStreamConfig,
  getDeviationStreamId,
} from '../../../redux/stash/helpers';
import { getCurrentFolder } from '../../../redux/stash/selectors';
import {
  getDailyChallengeTag,
  getGenerationJobs,
} from '../../../redux/dreamup/selectors';
import {
  GenerationStatus,
  InProcessGeneration,
} from '../../../redux/dreamup/types';
import DreamupThumb from '../DreamupThumb';
import EmptyState from '../EmptyState';
import DreamupPlaceholderThumb from '../DreamupPlaceholderThumb';
import DreamupErrorThumb from '../DreamupErrorThumb';
import DailyChallengeBanner from '../DailyChallengeBanner';
import s from './Content.scss';

interface Props {
  className?: string;
}

/**
 * Takes a list of generation jobs and returns a render function that's compatible with our Grid component. This is necessary
 * because grid doesn't take individual items: it takes a length and renders based off the index of the current grid item.
 *
 * So this function takes that job array and a `gridToArrayIndex` function to make that grid-index > array-index mapping easier.
 */
function useGenerationJobRenderer(generationJobs: InProcessGeneration[]) {
  /**
   * Each job can generate an arbitrary number of images. So instead of doing weird math that requires
   * looping over the job array multiple times to find the right cutoffs, we create a new array and loop through
   * the jobs once. For each job, we look at that job's number of images and add that number of duplicate entries
   * to the array.
   *
   * Then we can just index into this array of duplicates instead of doing weird math to determine what grid index
   * should render what.
   */
  const duplicatedGenerationJobs: (InProcessGeneration & { key: string })[] =
    useMemo(
      () =>
        generationJobs.reduce(
          (accumulator, job) => [
            ...accumulator,
            ...new Array(job.imageCount).fill(job),
          ],
          []
        ),
      [generationJobs]
    );

  return useCallback(
    (element: GridElement) => {
      const generationJob = duplicatedGenerationJobs[element.index];
      const key = `${generationJob.uuid}-${element.index}`;
      return generationJob.status === GenerationStatus.Failure ? (
        <DreamupErrorThumb
          key={key}
          width={element.width}
          height={element.height}
          style={element.style}
          className={s['grid-item']}
          errorCode={generationJob.errorCode}
        />
      ) : (
        <DreamupPlaceholderThumb
          key={key}
          width={element.width}
          height={element.height}
          style={element.style}
          className={s['grid-item']}
        />
      );
    },
    [duplicatedGenerationJobs]
  );
}

const Content: React.FC<Props> = ({ className }) => {
  const dailyChallengeTag = useSelector(getDailyChallengeTag);
  const generationJobs = Object.values(useSelector(getGenerationJobs));
  const currentFolder = useSelector(getCurrentFolder);
  const deviationStream = useNormalizedDeviationStream(
    getDeviationStreamId(currentFolder.folderId),
    600,
    deviationStreamConfig
  );
  const canFetchMore =
    deviationStream.hasMore &&
    !deviationStream.isFetching &&
    !deviationStream.errorMessage;

  const totalProcessingEntries = generationJobs.reduce(
    (accumulator, job) => accumulator + job.imageCount,
    0
  );

  const totalElementCount =
    totalProcessingEntries + deviationStream.items.length;

  const renderGenerationJobThumb = useGenerationJobRenderer(generationJobs);

  // Grid only renders off a count of elements, so we have to do the array indexing manually.
  const renderGridItem = useCallback(
    (element: GridElement) => {
      if (element.index < totalProcessingEntries) {
        return renderGenerationJobThumb(element);
      }

      const deviation =
        deviationStream.items[element.index - totalProcessingEntries];
      return (
        <DreamupThumb
          key={deviation.deviationId}
          deviation={deviation}
          width={element.width}
          height={element.height}
          style={element.style}
          className={s['grid-item']}
        />
      );
    },
    [renderGenerationJobThumb, deviationStream.items, totalProcessingEntries]
  );

  return (
    <main className={classnames(s['root'], className)}>
      {totalElementCount !== 0 && (
        <>
          {dailyChallengeTag && (
            <DailyChallengeBanner
              tag={dailyChallengeTag}
              className={s['banner']}
            />
          )}

          <Grid
            elementCount={totalElementCount}
            withShadowSpace={false}
            spacing={8}
            cookieType={MeasuredCookieType.DREAMUP}
          >
            {renderGridItem}
          </Grid>
        </>
      )}
      {totalElementCount === 0 && !deviationStream.isFetching && (
        <EmptyState className={s['empty-state']} />
      )}
      {deviationStream.isFetching && <LoadingIndicator view="llama" />}

      <OnScrollBottom
        checkOnMount
        checkOnUpdate={canFetchMore}
        onScrolledToBottom={deviationStream.fetchNext}
        distanceToBottom={300}
        distanceUnit={DISTANCE_UNIT.PX}
      />
    </main>
  );
};

Content.displayName = 'Content';

export default Content;
