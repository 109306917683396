import React, { MouseEventHandler, useCallback } from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import Slider from '@wix/da-ds/pkg/Slider';
import { ArtStyle } from '../../../../../../redux/dreamup/types';
import { getArtStyle } from '../../../../../../redux/dreamup/selectors';
import { changeArtStyle } from '../../../../../../redux/dreamup/actions';
import ImageRadioButton from '../../../ImageRadioButton';
import SliderArrowButton from '../SliderArrowButton';
import s from './ArtStyleSlider.scss';

export interface Props {
  gradient?: 'primary' | 'secondary';
}

const ArtStyleSlider: React.FC<Props> = ({ gradient = 'secondary' }) => {
  const { t } = useTranslation();
  const currentValue = useSelector(getArtStyle);
  const dispatchChangeArtStyle = useAction(changeArtStyle);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      dispatchChangeArtStyle(e.target.value as ArtStyle);
    },
    [dispatchChangeArtStyle]
  );

  return (
    <div className={s['radio-buttons']}>
      <Slider
        className={s['slider']}
        permArrows
        renderPrevButton={({ onClick, className: prevButtonClassName }) => (
          <div
            className={c(
              s['gradient-and-button'],
              s['left'],
              prevButtonClassName
            )}
          >
            <SliderArrowButton
              direction="left"
              onClick={
                onClick as MouseEventHandler<HTMLButtonElement> &
                  MouseEventHandler<HTMLAnchorElement>
              }
              className={s['arrow-button']}
            />
            <div role="presentation" className={c(
              s['gradient'],
              gradient === 'secondary' && s['gradient-secondary'],
              gradient === 'primary' && s['gradient-primary']
            )} />
          </div>
        )}
        renderNextButton={({ onClick, className: nextButtonClassName }) => (
          <div
            className={c(
              s['gradient-and-button'],
              s['right'],
              nextButtonClassName
            )}
          >
            <SliderArrowButton
              direction="right"
              onClick={
                onClick as MouseEventHandler<HTMLButtonElement> &
                  MouseEventHandler<HTMLAnchorElement>
              }
              className={s['arrow-button']}
            />
            <div role="presentation" className={s['gradient']} />
          </div>
        )}
      >
        {artStyles.map(artStyle => (
          <div key={artStyle.value} className={s['radio-button']}>
            <ImageRadioButton
              name="artStyle"
              value={artStyle.value}
              checked={artStyle.value === currentValue}
              backgroundImage={artStyle.background}
              onChange={handleChange}
              showDreamupLogo={artStyle.value === ArtStyle.Default}
              hasAccessibleText
            >
              <div className={s['radio-button-content']}>
                {t(artStyle.label)}
              </div>
            </ImageRadioButton>
          </div>
        ))}
      </Slider>
    </div>
  );
};

ArtStyleSlider.displayName = 'ArtStyleSlider';

export default ArtStyleSlider;

const artStyles: {
  label: string;
  value: ArtStyle;
  background: string;
}[] = [
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.default.label',
    value: ArtStyle.Default,
    background:
      'linear-gradient(226deg, #F493F0 -0.01%, #E2C5DB 41.85%, #CAECEC 84.41%, #9CE4E4 137.23%)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.anime.label',
    value: ArtStyle.Anime,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-anime.jpeg)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.anthro.label',
    value: ArtStyle.Anthro,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-anthro.jpeg)',
  },
  {
    label:
      'dreamup.header.settingsPanel.artStyle.radioButton.photographic.label',
    value: ArtStyle.Photographic,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-photographic.jpeg?1)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.fantasy.label',
    value: ArtStyle.Fantasy,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-fantasy.jpeg?1)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.vaporwave.label',
    value: ArtStyle.Vaporwave,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-vaporwave.jpeg?1)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.lowpoly.label',
    value: ArtStyle.Lowpoly,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-lowpoly.jpeg?1)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.origami.label',
    value: ArtStyle.Origami,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-origami.jpeg?1)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.lineArt.label',
    value: ArtStyle.LineArt,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-lineart.jpeg?1)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.craftClay.label',
    value: ArtStyle.CraftClay,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-clay.jpeg)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.cinematic.label',
    value: ArtStyle.Cinematic,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-cinematic.jpeg)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.3dModel.label',
    value: ArtStyle.ThreeDModel,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-3dmodel.jpeg)',
  },
  {
    label: 'dreamup.header.settingsPanel.artStyle.radioButton.pixelArt.label',
    value: ArtStyle.PixelArt,
    background:
      'url(https://st.deviantart.net/eclipse/dreamup/art-style-pixelart.jpeg?1)',
  },
];
