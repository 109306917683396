import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import SelectFieldContent from '@wix/da-ds/pkg/Dropdown/select/SelectField/SelectFieldContent';
import { getSimilarity } from '../../../../../redux/dreamup/selectors';
import { changeSimilarity } from '../../../../../redux/dreamup/actions';
import PanelInputField from '../PanelInputField';
import LabelText from '../LabelText';

interface Props {}

const ImageSimilaritySelectField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const similarity = useSelector(getSimilarity);
  const dispatchChangeSimilarity = useAction(changeSimilarity);

  return (
    <PanelInputField
      rootTag="label"
      label={
        <LabelText>
          {t('dreamup.header.settingsPanel.similarity.label')}
        </LabelText>
      }
      input={
        <SelectFieldContent
          id="prompt-strength-select"
          name="promptStrength"
          onSelect={dispatchChangeSimilarity}
          items={items}
          value={similarity}
          withBlankOption={false}
        />
      }
    />
  );
};

ImageSimilaritySelectField.displayName = 'ImageSimilaritySelectField';

export default ImageSimilaritySelectField;

const items = [
  {
    label: '10%',
    value: 10,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '30%',
    value: 30,
  },
  {
    label: '40%',
    value: 40,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '60%',
    value: 60,
  },
  {
    label: '70%',
    value: 70,
  },
  {
    label: '80%',
    value: 80,
  },
  {
    label: '90%',
    value: 90,
  },
  {
    label: '100%',
    value: 100,
  },
];
