import React from 'react';
import c from 'classnames';
import PlainButtonOrLink, {
  type Props as PlainButtonOrLinkProps,
} from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import s from './FooterLink.scss';

interface Props extends PlainButtonOrLinkProps {}

const FooterLink: React.FC<Props> = ({ children, className, ...rest }) => (
  <PlainButtonOrLink className={c(s['root'], className)} {...rest}>
    {children}
  </PlainButtonOrLink>
);

FooterLink.displayName = 'FooterLink';

export default FooterLink;
