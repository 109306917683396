import { handleActions } from 'redux-actions';
import { AppState } from '../../../types/store';
import { changeFolder } from './actions';

export default handleActions<AppState['stash'], any>(
  {
    [changeFolder.toString()]: (
      state,
      { payload: { folder } }: ReturnType<typeof changeFolder>
    ) => ({ ...state, currentFolder: folder }),
  },
  { maxCapacity: 1, freeCapacity: 0 }
);
