import { PapiRequestDreamsofartGenerate } from '@wix/da-papi-types';
import c from 'classnames';
import s from './aspectRatios.scss';

export const ratios: {
  label: string;
  value: NonNullable<PapiRequestDreamsofartGenerate['aspect_ratio']>;
  prefixClassName: string;
}[] = [
  {
    label:
      'dreamup.header.settingsPanel.aspectRatio.radioButton.oneToOne.label',
    value: '1:1',
    prefixClassName: c(s['aspect-ratio-illustration'], s['ratio-one-to-one']),
  },
  {
    label:
      'dreamup.header.settingsPanel.aspectRatio.radioButton.threeToFour.label',
    value: '3:4',
    prefixClassName: c(
      s['aspect-ratio-illustration'],
      s['ratio-three-to-four']
    ),
  },
  {
    label:
      'dreamup.header.settingsPanel.aspectRatio.radioButton.fourToThree.label',
    value: '4:3',
    prefixClassName: c(
      s['aspect-ratio-illustration'],
      s['ratio-four-to-three']
    ),
  },
  {
    label:
      'dreamup.header.settingsPanel.aspectRatio.radioButton.sixteenToNine.label',
    value: '16:9',
    prefixClassName: c(
      s['aspect-ratio-illustration'],
      s['ratio-sixteen-to-nine']
    ),
  },
  {
    label:
      'dreamup.header.settingsPanel.aspectRatio.radioButton.nineToSixteen.label',
    value: '9:16',
    prefixClassName: c(
      s['aspect-ratio-illustration'],
      s['ratio-nine-to-sixteen']
    ),
  },
];
