import React from 'react';
import c from 'classnames';
import HeadlessRadioButton, {
  type RadioButtonProps,
} from '@wix/da-a11y/pkg/components/formControls/RadioButton';
import s from './UpscaleRadioButton.scss';

export type Props = RadioButtonProps & {};

const UpscaleRadioButton: React.FC<Props> = ({
  className,
  children,
  checked,
  ...rest
}) => {
  return (
    <HeadlessRadioButton checked={checked} {...rest}>
      <div className={c(s['outer-circle'], checked && s['checked'], className)}>
        <div className={s['inner-circle']}>{children}</div>
      </div>
    </HeadlessRadioButton>
  );
};

UpscaleRadioButton.displayName = 'UpscaleRadioButton';

export default UpscaleRadioButton;
