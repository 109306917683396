import {
  PapiDeviation,
  PapiRequestDreamsofartGenerate,
  PapiTag,
} from '@wix/da-papi-types';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { createAction } from 'redux-actions';
import { DreamupModals } from '../../../constants';
import { reduxKey } from './constants';
import { ArtStyle, SubmissionError, VariationSource } from './types';

export const initializeDreamup = createAction(
  `${reduxKey}.INITIALIZE_DREAMUP`,
  (
    data: {
      restrictions;
      jobs;
      pointsPackages;
      dailyChallengeTag?: PapiTag;
      defaultFormValues?: {
        promptStrength?: number;
      };
    },
    options: {
      initialPrompt: string;
      expandSettings: boolean;
      persistFromStorage: boolean;
    }
  ) => ({
    data,
    options,
  })
);

export const clientLoaded = createAction(
  `${reduxKey}.CLIENT_LOADED`,
  () => ({})
);

export const changePrompt = createAction(
  `${reduxKey}.CHANGE_PROMPT`,
  (prompt: string) => ({ prompt })
);

export const clearPrompt = createAction(`${reduxKey}.CLEAR_PROMPT`, () => ({}));

export const changeSimilarity = createAction(
  `${reduxKey}.CHANGE_SIMILARITY`,
  (similarity: number) => ({ similarity })
);

export const changePromptStrength = createAction(
  `${reduxKey}.CHANGE_PROMPT_STRENGTH`,
  (promptStrength: number) => ({ promptStrength })
);

export const changeAspectRatio = createAction(
  `${reduxKey}.CHANGE_ASPECT_RATIO`,
  (
    aspectRatio: NonNullable<PapiRequestDreamsofartGenerate['aspect_ratio']>
  ) => ({
    aspectRatio,
  })
);

export const changeArtStyle = createAction(
  `${reduxKey}.CHANGE_ART_STYLE`,
  (artStyle: ArtStyle) => ({
    artStyle,
  })
);

/**
 * Sets up the UI and state to accept a variation generation request.
 */
export const startVariation = createAction(
  `${reduxKey}.START_VARIATION`,
  (
    deviation: PapiDeviation,
    options: {
      variationSource: VariationSource;
    }
  ) => ({ deviation, options })
);

/**
 * Clears the variation specific UI elements and state.
 */
export const clearVariation = createAction(
  `${reduxKey}.CLEAR_VARIATION`,
  () => ({})
);

export const toggleNegativePrompt = createAction(
  `${reduxKey}.TOGGLE_NEGATIVE_PROMPT`,
  () => ({})
);

export const changeNegativePrompt = createAction(
  `${reduxKey}.CHANGE_NEGATIVE_PROMPT`,
  (negativePrompt: string) => ({ negativePrompt })
);

/**
 * Shows a settings panel with advanced generation options
 */
export const showPromptSettingsPanel = createAction(
  `${reduxKey}.SHOW_PROMPT_SETTINGS_PANEL`,
  () => ({})
);

export const hidePromptSettingsPanel = createAction(
  `${reduxKey}.HIDE_PROMPT_SETTINGS_PANEL`,
  () => ({})
);

export const showStartingImageSelector = createAction(
  `${reduxKey}.SHOW_STARTING_IMAGE_SELECTOR`,
  () => ({})
);

export const hideStartingImageSelector = createAction(
  `${reduxKey}.HIDE_STARTING_IMAGE_SELECTOR`,
  () => ({})
);

export const generateFromPrompt = createAction(
  `${reduxKey}.GENERATE_FROM_PROMPT`,
  (usePoints: boolean) => ({
    usePoints,
  })
);

export const generationFromPromptInitiationSucceeded = createAction(
  `${reduxKey}.GENERATION_FROM_PROMPT_INITIATION_SUCCEEDED`,
  (generationId: number, imageCount: number, restrictions: any) => ({
    generationId,
    imageCount,
    restrictions,
  })
);

export const generationFromPromptInitiationFailed = createAction(
  `${reduxKey}.GENERATION_INITIATION_FAILED`,
  (error: SubmissionError, serverErrorMessage: string | undefined) => ({
    error,
    serverErrorMessage,
  })
);

export const upscaleDeviation = createAction(
  `${reduxKey}.UPSCALE_DEVIATION`,
  (
    deviation: PapiDeviation,
    usePoints: boolean,
    creativityLevel: 1 | 2 | 3 | 4 | 5 | undefined
  ) => ({ deviation, usePoints, creativityLevel })
);

export const upscaleInitiationSucceeded = createAction(
  `${reduxKey}.UPSCALE_INITIATION_SUCCEEDED`,
  (generationId: number, imageCount: number, restrictions: any) => ({
    generationId,
    imageCount,
    restrictions,
  })
);

export const upscaleInitiationFailed = createAction(
  `${reduxKey}.UPSCALE_INITIATION_FAILED`,
  () => ({})
);

/**
 * This is fired only after:
 * 1. The deviations for a prompt have been generated
 * 2. We've loaded the necessary data to display the deviation in our thumb grid
 *
 * At this point, we can swap out the placeholder thumbnails for the real one.
 */
export const generatedDeviationsLoaded = createAction(
  `${reduxKey}.GENERATED_DEVIATIONS_LOADED`,
  (generationId: number) => ({ generationId })
);

export const extendedDeviationRequestSucceeded = createAction(
  `${reduxKey}.EXTENDED_DEVIATION_REQUEST_SUCCEEDED`,
  (deviation: PapiDeviation) => ({ deviation })
);

export const extendedDeviationRequestFailed = createAction(
  `${reduxKey}.EXTENDED_DEVIATION_REQUEST_FAILED`,
  () => ({})
);

export const toggleBurgerMenu = createAction(
  `${reduxKey}.TOGGLE_BURGER_MENU`,
  () => ({})
);

export const closeBurgerMenu = createAction(
  `${reduxKey}.CLOSE_BURGER_MENU`,
  () => ({})
);

export const pushPromptInfoModal = (deviation: PapiDeviation) =>
  pushModal(DreamupModals.PROMPT_INFO, {
    params: { deviation },
    wrapInModal: false,
  });

export const pushUpscaleConfirmationModal = (deviation: PapiDeviation) =>
  pushModal(DreamupModals.UPSCALE_CONFIRMATION, {
    params: { deviation },
    wrapInModal: false,
  });

export const pushPointsModal = () =>
  pushModal(DreamupModals.POINTS, { wrapInModal: false });

export const fetchExtendedDeviation = createAction(
  `${reduxKey}.FETCH_EXTENDED_DEVIATION`,
  (deviation: PapiDeviation) => ({ deviation })
);
