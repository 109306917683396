import React from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrentSubmission } from '../../../../../../redux/dreamup/selectors';
import { SubmissionError } from '../../../../../../redux/dreamup/types';
import s from './PromptError.scss';

interface Props {
  className?: string;
}

function usePromptErrorText() {
  const { t } = useTranslation();
  const currentSubmission = useSelector(getCurrentSubmission);

  switch (currentSubmission.error) {
    case SubmissionError.ForbiddenTermsInPrompt:
      return t('dreamup.header.error.forbiddenTerm');
    case SubmissionError.NotEnoughPoints:
      return t('dreamup.header.error.notEnoughPoints');
    case SubmissionError.NotEnoughStashSpace:
      return t('dreamup.header.error.notEnoughStashSpace');
    case SubmissionError.ValidationError:
      if (currentSubmission.serverErrorMessage) {
        return currentSubmission.serverErrorMessage;
      }
      return t('dreamup.header.error.unknown');
    case SubmissionError.Unknown:
    default:
      if (currentSubmission.serverErrorMessage) {
        return currentSubmission.serverErrorMessage;
      }
      return t('dreamup.header.error.unknown');
  }
}

const PromptError: React.FC<Props> = ({ className }) => {
  const text = usePromptErrorText();
  return <div className={c(s['root'], className)}>{text}</div>;
};

PromptError.displayName = 'PromptError';

export default PromptError;
