import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPointsCount } from '@wix/da-shared-react/pkg/publicSession/selectors';
import AccountStatusDescription from '../AccountStatusDescription';
import GetMorePointsButton from '../GetMorePointsButton';

interface Props {}

const PointsBalance: React.FC<Props> = () => {
  const { t } = useTranslation();
  const pointsCount = useSelector(getPointsCount);
  return (
    <AccountStatusDescription>
      {t('dreamup.accountStatus.pointsBalance.balance', {
        count: pointsCount,
      })}
      <GetMorePointsButton />
    </AccountStatusDescription>
  );
};

PointsBalance.displayName = 'PointsBalance';

export default PointsBalance;
