import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import {
  BiData,
  BuyCoreButtonBiEvent,
  DreamupAddImageClickBiEvent,
  DreamupImageRightsConfirmClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import AddImageIcon from '@wix/da-ds/pkg/Icons/24x24/AddImage';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import { pushGlobalConfirmationModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { useRestrictedToCore } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictedToCore';
import { showStartingImageSelector } from '../../../../../../redux/dreamup/actions';
import ButtonBorder from '../../partials/ButtonBorder';
import s from './StartingImageButton.scss';

interface Props {}

/**
 * Shows the media selector so that the user can choose an image to start a deviation from.
 *
 * Note: this button is not responsible for rendering the selected image within the input area. It's only responsibility is
 * the button that triggers the media selector.
 */
const StartingImageButton: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatchShowStartingImageSelector = useAction(
    showStartingImageSelector
  );

  const dispatchPushGlobalConfirmationModal = useAction(
    pushGlobalConfirmationModal
  );

  const handleClick = useRestrictedToCore(
    useCallback(() => {
      dispatchPushGlobalConfirmationModal({
        cancelBtnLabelTransKey: 'common.cancel',
        confirmBtnLabelTransKey:
          'dreamup.modal.imageRights.confirmButton.label',
        titleTransKey: 'dreamup.modal.imageRights.title',
        textTransKey: 'dreamup.modal.imageRights.description',
        onConfirm: () => dispatchShowStartingImageSelector(),
        confirmBtnBiData: BiData<DreamupImageRightsConfirmClickBiEvent>({
          evid: 925,
        }),
      });
    }, [
      dispatchPushGlobalConfirmationModal,
      dispatchShowStartingImageSelector,
    ]),
    Url.dreamupLink(),
    {
      confirmBtnBiData: BiData<BuyCoreButtonBiEvent>({
        evid: 375,
        referral_info: ReferralType.DREAMUP_CORE_UPSELL,
      }),
    }
  );

  return (
    <ButtonBorder>
      <IconButton
        size="medium"
        type="button"
        icon={AddImageIcon}
        onClick={handleClick}
        tooltipText={t('dreamup.startingImageButton.tooltip.text')}
        aria-label={t('dreamup.startingImageButton.a11y.label')}
        biData={BiData<DreamupAddImageClickBiEvent>({ evid: 924 })}
        className={s['button']}
      />
    </ButtonBorder>
  );
};

StartingImageButton.displayName = 'StartingImageButton';

export default StartingImageButton;
