import { createAction } from 'redux-actions';
import type { PapiDeviation } from '@wix/da-papi-types';
import type { Faq } from '@wix/da-shared-react/pkg/Faqs';
import { reduxKey } from './constants';

export const initializeAnonLanding = createAction(
  `${reduxKey}.INITIALIZE`,
  (deviations: PapiDeviation[], faqs: Faq[]) => ({ deviations, faqs })
);

export const storeFormValuesForJoinPersistence = createAction(
  `${reduxKey}.STORE_FORM_VALUES_FOR_JOIN_PERSISTENCE`,
  () => ({})
);
