import React from 'react';
import { useTranslation } from 'react-i18next';
import type { PapiDeviation } from '@wix/da-papi-types';
import MoreActionsIcon from '@wix/da-ds/pkg/Icons/24x24/More';
import { useDreamupDeleteAction } from '../../../hooks';
import ActionBarIconButtonWithContextMenu from '../ActionBarIconButtonWithContextMenu';

interface Props {
  deviation: PapiDeviation;
}

const MoreMenuButton: React.FC<Props> = ({ deviation }) => {
  const { t } = useTranslation();
  const deleteAction = useDreamupDeleteAction(deviation, {
    biData: {
      sectionname: 'prompt_info',
      component: 'prompt',
    },
  });
  return (
    <ActionBarIconButtonWithContextMenu
      icon={MoreActionsIcon}
      items={[
        {
          label: t('dreamup.modal.promptInfo.actions.delete.label'),
          onClick: deleteAction,
        },
      ]}
      aria-label={t('dreamup.modal.promptInfo.actions.more.label')}
      tooltipText={t('dreamup.modal.promptInfo.actions.more.label')}
    />
  );
};

MoreMenuButton.displayName = 'MoreMenuButton';

export default MoreMenuButton;
