import { useSelector } from 'react-redux';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  getCanSubmitFreePrompts,
  getCanSubmitPaidPrompts,
} from '../../../redux/dreamup/selectors';

/**
 * Indicates what clicking the confirm button on the upscale confirmation modal should do.
 */
export enum UpscaleConfirmationAction {
  SubmitWithPrompt = 'submit-with-prompt',
  SubmitWithPoints = 'submit-with-points',
  UpgradeToCore = 'upgrade-to-core',
  PurchasePoints = 'purchase-points',
}

/**
 * Returns an enum indicating what action the confirmation modal should take when the confirmation modal's
 * confirm button is clicked.
 */
export function useUpscaleConfirmationAction(): UpscaleConfirmationAction {
  const hasCore = useSelector(getCurrentUserHasCore);
  const canSubmitPaidPrompts = useSelector(getCanSubmitPaidPrompts);
  const canSubmitFreePrompts = useSelector(getCanSubmitFreePrompts);

  if (canSubmitFreePrompts) {
    return UpscaleConfirmationAction.SubmitWithPrompt;
  }

  if (!hasCore) {
    return UpscaleConfirmationAction.UpgradeToCore;
  }

  if (canSubmitPaidPrompts) {
    return UpscaleConfirmationAction.SubmitWithPoints;
  }

  return UpscaleConfirmationAction.PurchasePoints;
}
