import { DreamupAnonStoreSlice } from './types';
import { reduxKey } from './constants';
import { createSelector } from 'reselect';

function getStoreSlice(state: any): DreamupAnonStoreSlice {
  if (!state[reduxKey]) {
    return {} as any;
  }
  return state[reduxKey];
}

export const getFaqs = createSelector(getStoreSlice, slice => slice.faqs);
