import React, { useContext, useEffect } from 'react';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import {
  ThemeOptions,
  ThemeSkin,
} from '@wix/da-react-context/pkg/ThemeContext';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import type { ModalsRenderFunction } from '@wix/da-shared-react/pkg/Modals/DaModal';
import { DreamupModals } from '../../../../constants';
import { clientLoaded } from '../../../redux/dreamup/actions';
import Page from '../Page';
import AnonLandingPage from '../AnonLandingPage';
import PointsModal from '../PointsModal';
import PromptGuidelinesModal from '../PromptGuidelinesModal';
import UpscaleConfirmationModal from '../UpscaleConfirmationModal';
import InfoModal from '../InfoModal';
import s from './DreamupApp.scss';

export interface Props {
  language: string;
  environment?: RuntimeEnvironment;
}

const renderModals: ModalsRenderFunction = (modalType, popModal, params) => {
  switch (modalType) {
    case DreamupModals.POINTS:
      return <PointsModal onRequestClose={popModal} />;
    case DreamupModals.PROMPT_GUIDELINES:
      return (
        <PromptGuidelinesModal
          onRequestClose={popModal}
          onConfirm={params.onConfirm}
        />
      );
    case DreamupModals.PROMPT_INFO:
      return (
        <InfoModal onRequestClose={popModal} deviation={params.deviation} />
      );
    case DreamupModals.UPSCALE_CONFIRMATION:
      return (
        <UpscaleConfirmationModal
          onRequestClose={popModal}
          deviation={params.deviation}
        />
      );
    default:
      return null;
  }
};

const DreamupPage: React.FC<{}> = () => {
  const { isLoggedIn } = useContext(SessionContext);
  const dispatchClientLoaded = useAction(clientLoaded);

  useEffect(() => {
    dispatchClientLoaded();
  }, [dispatchClientLoaded]);

  return isLoggedIn ? <Page /> : <AnonLandingPage />;
};
DreamupPage.displayName = 'DreamupPage';

const DreamupApp: React.FC<Props> = ({ language, environment }) => {
  return (
    <DaApp
      language={language}
      environment={environment}
      theme={ThemeOptions.DARK}
      themeSkin={ThemeSkin.NONE}
      modalsRenderFunc={renderModals}
      bodyClassName={s['root']}
      disableHeadTags
    >
      <DreamupPage />
    </DaApp>
  );
};
DreamupApp.displayName = 'DreamupApp';

export default DreamupApp;
