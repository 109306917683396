import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './Detail.scss';

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
  className?: string;
}

/**
 * A heading and a value. Groups of these represent independent details that aren't necessarily related.
 */
const Detail: React.FC<Props> = ({ label, value, className }) => {
  return (
    <Section className={c(s['root'], className)}>
      <Heading className={s['heading']}>{label}</Heading>
      <p className={s['value']}>{value}</p>
    </Section>
  );
};

Detail.displayName = 'Detail';

export default Detail;
