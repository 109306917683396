import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Url } from '@wix/da-url';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import DeviationTorpedo from '@wix/da-shared-react/pkg/Torpedo/DeviationTorpedo';
import { ANON_LANDING_DEVIATION_STREAM_ID } from '../../../../redux/dreamup/constants';
import s from './ArtSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const ArtSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { items } = useStream(ANON_LANDING_DEVIATION_STREAM_ID);

  return (
    <Section className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['heading-and-description']}>
        <Heading className={s['heading']}>
          {t('dreamupAnon.exploreArt.heading')}
        </Heading>
        <p className={s['description']}>
          {t('dreamupAnon.exploreArt.description')}
        </p>
      </div>
      <div className={s['grid']}>
        {/* MeasuredTorpedo has two elements inside of it, so it'd mess up the root element's grid layout without this wrapper */}
        <DeviationTorpedo
          items={items}
          container={{ height: 795 }}
          layoutConfig={{
            itemSpacingDesktop: 8,
            itemSpacingMobile: 8,
            rowSizeDesktop: 192.75,
            rowSizeMobile: 192.75,
          }}
          maxRows={4}
          cookieDim="both"
          cookieType={MeasuredCookieType.ANON_DREAMUP_LANDING_TORPEDO}
          hideIncompleteRows
        />
      </div>
      <BrandedButton
        size="large"
        variant="dreamup-purple"
        href={Url.tagsPageLink('dreamup')}
      >
        {t('dreamupAnon.exploreArt.button.label')}
      </BrandedButton>
      <div role="presentation" className={s['gradient-top-right']} />
      <div role="presentation" className={s['gradient-bottom-left']} />
    </Section>
  );
};

ArtSection.displayName = 'ArtSection';

export default ArtSection;
