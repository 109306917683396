import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { BiData, DAProtectManagePlanClickBiEvent } from '@wix/da-bi/pkg/events';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';

import s from './ManageCoreLink.scss';

interface Props {}

const ManageCoreLink: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <PlainButtonOrLink
      href={Url.coreMembershipLink(Url.dreamupLink())}
      className={s['root']}
      target="_blank"
      biData={BiData<DAProtectManagePlanClickBiEvent>({ evid: 903 })}
    >
      {t('dreamup.accountStatus.manageLink.text')}
    </PlainButtonOrLink>
  );
};

ManageCoreLink.displayName = 'ManageCoreLink';

export default ManageCoreLink;
