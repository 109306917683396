import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  type PapiDaDeviationStashInit,
  PapiDeviation,
  type PapiDreamsofartGenerate,
  type PapiDreamsofartUpscale,
  PapiRequestDreamsofartGenerate,
  type PapiRequestDreamsofartUpscale,
  PapiUser,
} from '@wix/da-papi-types';
import ddt from '@wix/da-ddt';
import { BiData, DreamupClickCreateBiEvent } from '@wix/da-bi/pkg/events';
import {
  normalizeDeviation,
  normalizeDeviations,
} from '@wix/da-shared-react/pkg/redux/normalizr/helpers';
import { getCompliantLocalStorageItem } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/selectors';
import { removeCompliantLocalStorageItem } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/actions';
import { CompliantLocalStorageKeys } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/constants';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import { logPageView } from '@wix/da-shared-react/pkg/biLogger/redux/actions';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { updatePointsCount } from '@wix/da-shared-react/pkg/publicSession/actions';
import { getCurrentUser } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import { dreamupGenerationSucceeded } from '@wix/da-shared-react/pkg/redux/dreamup/actions';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';
import { DreamupModals } from '../../../constants';
import { getDeviationStreamId } from '../stash/helpers';
import { getCurrentFolder } from '../stash/selectors';
import type { JoinPersistentFormValues } from '../dreamupAnon/types';
import {
  changeArtStyle,
  changePrompt,
  clientLoaded,
  extendedDeviationRequestFailed,
  extendedDeviationRequestSucceeded,
  fetchExtendedDeviation,
  generateFromPrompt,
  generatedDeviationsLoaded,
  upscaleDeviation,
  upscaleInitiationSucceeded,
  upscaleInitiationFailed,
  generationFromPromptInitiationFailed,
  generationFromPromptInitiationSucceeded,
} from './actions';
import {
  getCachedDeviation,
  getFormValues,
  getFreePromptsRemaining,
  getShouldPullFormValuesFromLocalStorage,
  getVariationTarget,
} from './selectors';
import { DreamupStoreSlice, SubmissionError } from './types';
import { Url } from '@wix/da-url';

function* pageViewHandler() {
  const remainingPrompts = yield select(getFreePromptsRemaining);
  yield put(
    logPageView({
      view: 'dreamup',
      component: 'main',
      num_of_prompts: remainingPrompts,
    })
  );
}

function* handleClientLoaded(action: ReturnType<typeof clientLoaded>) {
  const shouldPullFormValuesFromLocalStorage = yield select(
    getShouldPullFormValuesFromLocalStorage
  );
  if (!shouldPullFormValuesFromLocalStorage || typeof window === 'undefined') {
    return;
  }

  const rawPersistedLocalStorageItem = yield select(
    getCompliantLocalStorageItem,
    CompliantLocalStorageKeys.DREAMUP_ANON_FORM
  );

  try {
    const parsedFormData: JoinPersistentFormValues = JSON.parse(
      rawPersistedLocalStorageItem
    );

    yield put(changePrompt(parsedFormData.prompt));
    yield put(changeArtStyle(parsedFormData.artStyle));

    // We wipe the related local storage data and drop the query param that triggered this storage persistence to avoid
    // any unexpected behaviour on future reloads. We don't want users bookmarking dreamup with a url that would throw an
    // error every time they visit.
    yield put(
      removeCompliantLocalStorageItem(
        CompliantLocalStorageKeys.DREAMUP_ANON_FORM
      )
    );
    window.history.replaceState({}, '', Url.dreamupLink());

    ddt.info(
      'dreamup',
      'Persisted local storage to dreamup form successfully. Wiped related local storage.'
    );
  } catch (e) {
    ddt.error(
      'dreamup',
      'Failed to persist local storage value to dreamup form when requested.\n\n',
      'value we got from local storage:\n',
      rawPersistedLocalStorageItem,
      '\n\n',
      'error we got trying to parse it:\n',
      e
    );
  }
}

function decodePromptSubmissionError(response): SubmissionError {
  switch (response.errorCode) {
    case 0:
      return SubmissionError.ValidationError;
    case 1:
      return SubmissionError.ForbiddenTermsInPrompt;
    case 2:
      return SubmissionError.NotEnoughPoints;
    case 3:
      return SubmissionError.NotEnoughStashSpace;
    default:
      return SubmissionError.Unknown;
  }
}

function* handleGenerateFromPrompt(
  action: ReturnType<typeof generateFromPrompt>
) {
  const variationTarget: PapiDeviation | undefined = yield select(
    getVariationTarget
  );
  const formValues: DreamupStoreSlice['formValues'] = yield select(
    getFormValues
  );

  yield logBiEvent(
    BiData<DreamupClickCreateBiEvent>({
      evid: 912,
      prompt: formValues.prompt,
      is_valid: formValues.prompt.trim().length > 0,
      image_similarity: formValues.similarity,
      aspect_ratio: formValues.aspectRatio,
      prompt_weight: formValues.promptStrength,
      art_style: formValues.artStyle,
      negative_prompt: formValues.negativePrompt,
    })
  );

  const requestData: PapiRequestDreamsofartGenerate = {
    prompt: formValues.prompt,
    use_points: action.payload.usePoints,
    deviationid: variationTarget?.deviationId,

    // Similarity is a value between 0 and 99 on the frontend. 0 being less similar, 99 being very similar.
    // This is inverted on the backend. 0 is very similar and 99 is less similar. So we subtract 99 to
    // invert the frontend value before sending it to the backend.
    //
    // Please note that the AI actually uses values between 0 (inclusive) and 1 (exclusive). The backend
    // converts the 0-99 we send into that range.
    similarity: variationTarget ? 100 - formValues.similarity : undefined,
    aspect_ratio: formValues.aspectRatio,
    guidance_scale: formValues.promptStrength,
    style: formValues.artStyle,
    negative_prompt: formValues.negativePrompt,
  };

  const response: PapiDreamsofartGenerate = yield call(
    requestPuppy,
    {
      url: 'generate',
      method: 'post',
      data: requestData,
    },
    () => ({
      success: true,
      generationId: 5,
      restrictions: {
        pointsBalance: 912,
      },
    }),
    'dreamsofart'
  );

  /*
   TODO: Manually checking for errorCode === 0 until the request code is updated to handle `errorCode === 0` correctly
  */
  if (
    !response ||
    isApiError(response) ||
    (response as any).errorCode === 0 ||
    response.generationId === undefined
  ) {
    yield put(
      generationFromPromptInitiationFailed(
        decodePromptSubmissionError(response),
        (response as any).errorDescription
      )
    );
  } else {
    yield all([
      put(updatePointsCount(response.restrictions.pointsBalance)),
      put(
        generationFromPromptInitiationSucceeded(
          response.generationId,
          response.imageCount ?? 1,
          response.restrictions
        )
      ),
    ]);
  }
}

function* handleUpscaleDeviation(action: ReturnType<typeof upscaleDeviation>) {
  /**
  yield logBiEvent(
    BiData<???>({
      evid: ???,
    })
  );
  */

  const requestData: PapiRequestDreamsofartUpscale = {
    deviationid: action.payload.deviation.deviationId,
    use_points: action.payload.usePoints,
    creativity_factor: action.payload.creativityLevel,
  };

  const response: PapiDreamsofartUpscale = yield call(
    requestPuppy,
    {
      url: 'upscale',
      method: 'post',
      data: requestData,
    },
    () => ({
      success: true,
      generationId: 5,
      restrictions: {
        pointsBalance: 912,
      },
    }),
    'dreamsofart'
  );

  // TODO: Manually checking for errorCode === 0 until the request code is updated to handle `errorCode === 0` correctly
  if (
    !response ||
    isApiError(response) ||
    (response as any).errorCode === 0 ||
    response.generationId === undefined
  ) {
    yield all([
      putErrorNotification(i18n.t('dreamup.toastError.upscaleDeviationFailed')),
      put(upscaleInitiationFailed()),
    ]);
    ddt.error('dreamup', 'Upscale job initiation failed', response);
  } else {
    yield all([
      put(updatePointsCount(response.restrictions.pointsBalance)),
      put(
        upscaleInitiationSucceeded(
          response.generationId,
          response.imageCount ?? 1,
          response.restrictions
        )
      ),
    ]);
  }
}

function* handleDreamupGenerationSucceeded(
  action: ReturnType<typeof dreamupGenerationSucceeded>
) {
  const currentFolder = yield select(getCurrentFolder);

  const response = yield call(requestPuppy, {
    url: '/deviation/multifetch',
    method: 'get',
    params: {
      deviationids: action.payload.deviationIds,
    },
  });

  if (isApiError(response)) {
    yield putErrorNotification(
      'Sorry, we were unable to load your new deviations. Please refresh the page to try again.'
    );
    return;
  }
  const deviations = response.deviations;
  const deviationStreamId = getDeviationStreamId(currentFolder.folderId);
  const { entities, result } = normalizeDeviations(deviations);
  yield put(putEntities({ entities }));
  yield put(
    withOffset.actions.insertItems({
      streamId: deviationStreamId,
      items: result,
      offset: 0,
    })
  );
  yield put(generatedDeviationsLoaded(action.payload.generationId));
}

function* handlePushPromptInfoModal(action: ReturnType<typeof pushModal>) {
  if (action.payload.type !== DreamupModals.PROMPT_INFO) {
    return;
  }

  const sparseDeviation: PapiDeviation =
    action.payload.options.params.deviation;

  yield put(fetchExtendedDeviation(sparseDeviation));
}

function* handleFetchExtendedDeviation(
  action: ReturnType<typeof fetchExtendedDeviation>
) {
  const { deviation: potentiallySparseDeviation } = action.payload;
  const cachedExtendedDeviation = yield select(
    getCachedDeviation,
    potentiallySparseDeviation.deviationId
  );

  if (cachedExtendedDeviation) {
    return;
  }

  const currentUser = (yield select(getCurrentUser)) as PapiUser;

  const response: PapiDaDeviationStashInit = yield call(
    requestPuppy,
    {
      url: 'stash/init',
      params: {
        username: currentUser.username,
        deviationid: potentiallySparseDeviation.stashPrivateid,
        type: 'art',
        include_session: false,
      },
    },
    () => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line global-require
        const { Fake } = require('@wix/da-sample-data');
        return {
          deviation: Fake.extendDeviation(potentiallySparseDeviation, {
            withDreamup: true,
            withStash: true,
            withMuro: true,
          }),
        };
      }
      return false;
    },
    'dadeviation'
  );

  if (isApiError(response)) {
    yield put(extendedDeviationRequestFailed());
  } else {
    const entities = normalizeDeviation(response.deviation);
    yield put(putEntities({ entities }));
    yield put(extendedDeviationRequestSucceeded(response.deviation));
  }
}

export function* dreamupSaga() {
  yield all([
    takeEvery(appMounted, pageViewHandler),
    takeEvery(clientLoaded, handleClientLoaded),
    takeLatest(generateFromPrompt, handleGenerateFromPrompt),
    takeLatest(upscaleDeviation, handleUpscaleDeviation),
    takeLatest(dreamupGenerationSucceeded, handleDreamupGenerationSucceeded),
    takeLatest(pushModal, handlePushPromptInfoModal),
    takeLatest(fetchExtendedDeviation, handleFetchExtendedDeviation),
  ]);
}
