import React from 'react';
import type { PapiDeviation } from '@wix/da-papi-types';
import { getDreamupPrompt } from '@wix/da-shared-react/pkg/types/deviation/getters';
import MeasuredArtView from '../_partials/MeasuredArtView';
import ScrollablePrompt from '../_partials/ScrollablePrompt';
import DetailBlock from '../_partials/DetailBlock';
import ActionsBar from './ActionsBar';
import s from './DesktopContent.scss';

interface Props {
  deviation: PapiDeviation;
}

const DesktopContent: React.FC<Props> = ({ deviation }) => {
  return (
    <div className={s['root']}>
      <div className={s['image-wrapper']}>
        <MeasuredArtView deviation={deviation} />
      </div>
      <div className={s['after-image-area']}>
        <ActionsBar deviation={deviation} className={s['actions-bar']} />
        <div className={s['prompt-and-details']}>
          <ScrollablePrompt value={getDreamupPrompt(deviation)} />
          <DetailBlock deviation={deviation} orientation="horizontal" />
        </div>
      </div>
    </div>
  );
};

DesktopContent.displayName = 'DesktopContent';

export default DesktopContent;
