import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import HowToEntry from './HowToEntry';
import s from './HowToSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const HowToSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Section className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['sizing-wrapper']}>
        <Heading className={s['heading']}>
          {t('dreamupAnon.howTo.heading')}
        </Heading>
        <ol className={s['list']}>
          <HowToEntry
            className={s['list-entry']}
            label={t('dreamupAnon.howTo.bullet1.label')}
            sublabel={t('dreamupAnon.howTo.bullet1.sublabel')}
          />
          <HowToEntry
            className={s['list-entry']}
            label={t('dreamupAnon.howTo.bullet2.label')}
            sublabel={t('dreamupAnon.howTo.bullet2.sublabel')}
          />
          <HowToEntry
            className={s['list-entry']}
            label={t('dreamupAnon.howTo.bullet3.label')}
            sublabel={t('dreamupAnon.howTo.bullet3.sublabel')}
          />
          <HowToEntry
            className={s['list-entry']}
            label={t('dreamupAnon.howTo.bullet4.label')}
            sublabel={t('dreamupAnon.howTo.bullet4.sublabel')}
          />
          <HowToEntry
            className={s['list-entry']}
            label={t('dreamupAnon.howTo.bullet5.label')}
            sublabel={t('dreamupAnon.howTo.bullet5.sublabel')}
          />
        </ol>
        <BrandedButton size="large" variant="dreamup-purple" href="#">
          {t('dreamupAnon.howTo.button.label')}
        </BrandedButton>
      </div>
    </Section>
  );
};

HowToSection.displayName = 'HowToSection';

export default HowToSection;
