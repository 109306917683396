import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { getPointsCount } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  getFreePromptsLimit,
  getFreePromptsRemaining,
} from '../../../../redux/dreamup/selectors';
import { pushPointsModal } from '../../../../redux/dreamup/actions';
import { UpscaleConfirmationAction } from '../useUpscaleConfirmationAction';
import s from './PromptCountAndPointsBalance.scss';

interface Props {
  confirmationAction: UpscaleConfirmationAction;
}

function usePromptCountText() {
  const freePromptsRemaining = useSelector(getFreePromptsRemaining);
  const freePromptsLimit = useSelector(getFreePromptsLimit);

  return (
    <Trans
      // FEATURE CLEANUP: merge this translation key + remove dreamup.modal.upscaleConfirmation.promptCount.noPromptsRemaining.core
      i18nKey="dreamup.modal.upscaleConfirmationV2.promptCount"
      count={freePromptsRemaining}
      values={{
        limit: freePromptsLimit,
      }}
      components={{
        b: <b />,
      }}
    />
  );
}

const PromptCountAndPointsBalance: React.FC<Props> = ({
  confirmationAction,
}) => {
  const { t } = useTranslation();
  const promptCountText = usePromptCountText();
  const pointsCount = useSelector(getPointsCount);
  const dispatchPushPointsModal = useAction(pushPointsModal);

  return (
    <div>
      <div className={s['prompt-count']}>{promptCountText}</div>
      {[
        UpscaleConfirmationAction.PurchasePoints,
        UpscaleConfirmationAction.SubmitWithPoints,
      ].includes(confirmationAction) && (
        <div className={s['points']}>
          {t('dreamup.modal.upscaleConfirmation.pointsBalance', {
            count: pointsCount,
          })}{' '}
          <PlainButtonOrLink
            onClick={dispatchPushPointsModal}
            className={c(s['get-more-link'], 'text-link-level-2-primary')}
          >
            {t('dreamup.modal.upscaleConfirmation.pointsBalance.buyButton')}
          </PlainButtonOrLink>
        </div>
      )}
    </div>
  );
};

PromptCountAndPointsBalance.displayName = 'PromptCountAndPointsBalance';

export default PromptCountAndPointsBalance;
