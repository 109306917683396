import React, { useContext } from 'react';
import c from 'classnames';
import PlainButtonOrLink, {
  type Props as PlainButtonOrLinkProps,
} from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import BurgerIcon from '@wix/da-ds/pkg/Icons/Burger';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { limitNumber } from '@wix/da-shared-react/pkg/SiteHeader/helpers';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import s from './BurgerButton.scss';

interface Props extends PlainButtonOrLinkProps {
  suppressIndicator?: boolean;
}

const BurgerButton: React.FC<Props> = ({
  className,
  suppressIndicator,
  ...rest
}) => {
  const { counts: { feedback = 0 } = {} } = useContext(SessionContext);
  return (
    <PlainButtonOrLink className={c(s['root'], className)} {...rest}>
      <BurgerIcon disableDefaultColors size={IconSize.MEDIUM} />
      {!!feedback && !suppressIndicator && (
        <div className={s['notif-count']}>{limitNumber(feedback)}</div>
      )}
    </PlainButtonOrLink>
  );
};

BurgerButton.displayName = 'BurgerButton';

export default BurgerButton;
