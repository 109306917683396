import {
  PapiDeviation,
  PapiRequestDreamsofartGenerate,
  PapiTag,
} from '@wix/da-papi-types';
import { reduxKey } from './constants';

/**
 * Prompt submissions could be rejected or outright fail for a number of reasons.
 */
export enum SubmissionError {
  Unknown = 'unknown',
  ForbiddenTermsInPrompt = 'forbidden-terms',
  NotEnoughStashSpace = 'not-enough-stash-space',
  NotEnoughPoints = 'not-enough-points',
  ValidationError = 'validation-error',
}

/**
 * We submit prompts to the server for approval. This enum helps us keep track of a submission network request's current status.
 */
export enum SubmissionStatus {
  Idle = 'idle',
  AwaitingResponse = 'awaiting-response',
  Error = 'error',
}

export enum NetworkStatus {
  Idle = 'idle',
  AwaitingResponse = 'awaiting-response',
  Error = 'error',
}

/**
 * The status of a generation job that has been successfully submitted.
 */
export enum GenerationStatus {
  /**
   * The generation job is still being processed by the backend. We haven't heard anything through
   * Hub yet.
   */
  Processing = 'processing',
  Success = 'success',
  Failure = 'failure',
}

/**
 * These errors occur only after a prompt has been processed and generation has finished. There may be issues with the
 * generated images that necessitate us not displaying them. e.g. a TOS violation.
 */
export enum GenerationError {
  TosViolation = 6,
}

/**
 * A generation job that has been successfully submitted and we're just waiting for the backend to tell
 * us it's finished via Hub.
 */
export interface InProcessGeneration {
  /**
   * This is not the actual uuid of the final deviation(s).
   *
   * This field is a server-side id tied to the generation job.
   */
  uuid: number;

  /**
   * Whether the generation job is still in progress, has finished, or has errored out.
   */
  status: GenerationStatus;

  /**
   * The number of images this job will generate.
   */
  imageCount: number;

  /**
   * If {@link InProcessGeneration.status} indicates a failure, this error number will be populated with a number indicating the
   * cause.
   */
  errorCode?: GenerationError;
}

export enum ArtStyle {
  Default = 'default',
  Fantasy = 'fantasy',
  Anime = 'anime',
  Anthro = 'anthro',
  Photographic = 'photographic',
  Vaporwave = 'vaporwave',
  Lowpoly = 'lowpoly',
  Origami = 'origami',
  LineArt = 'line_art',
  CraftClay = 'craft_clay',
  Cinematic = 'cinematic',
  ThreeDModel = '3d_model',
  PixelArt = 'pixel_art',
}

/**
 * From whence a user selected a deviation for use as a variation target.
 */
export enum VariationSource {
  Dreamup = 'dreamup',
  MediaSelector = 'media-selector',
}

export interface DreamupStoreSlice {
  /**
   * Prompts that the server returned on initial load or confirmed after a prompt request. Our hub code
   * will be responsible for dispatching the completion actions that update the state of individual prompts.
   */
  generationJobs: {
    [uuid: number]: InProcessGeneration;
  };

  formValues: {
    /**
     * The prompt the user has entered. This is stored in redux because we have to interact with it in a variety of
     * places.
     */
    prompt: string;

    /**
     * Used for the generation of image variations. How similar to the original image that the result will be.
     */
    similarity: number;

    /**
     * What dimensions should the resulting images be?
     *
     * NonNullable because the UI has a default set and users can't unselect the option
     * entirely.
     */
    aspectRatio: NonNullable<PapiRequestDreamsofartGenerate['aspect_ratio']>;

    /**
     * I'm not sure exactly what this means, but it probably makes the prompt have more of an effect when paired with
     * the art style input or a variation target? Anyway, meant to be between 0 and 20.
     */
    promptStrength: number;

    /**
     * A string that maps to an art style. Abstract, anime, vaporwave?
     */
    artStyle: ArtStyle;

    /**
     * Whether our negative prompt input is visible.
     */
    isNegativePromptInputVisible: boolean;

    /**
     * The negative prompt the user has entered. This is stored in redux because we have to interact with it in a variety of
     * places.
     */
    negativePrompt: string;
  };

  isPromptSettingsPanelVisible: boolean;

  /**
   * If `true`, we'll try to pull some form values stored within local storage under the key {@link CompliantLocalStorageKeys.DREAMUP_ANON_FORM}.
   */
  shouldPullFormValuesFromLocalStorage: boolean;

  /**
   * If the user has an image selected as input for our AI, this'll be it.
   */
  variationTarget?: PapiDeviation;

  /**
   * From whence a user selected a deviation for use as a variation target. There are different UI interactions with the
   * prompt depending on this. e.g. if the source is dreamup, we clear the prompt if we remove the variation target
   */
  variationSource?: VariationSource;

  /**
   * To avoid repeat calls to our deviation init endpoint, we'll cache the ones we've already fetched.
   *
   * If this gets any more complicated, we should probably normalize all these entries so there is a single source of
   * truth for deviation data.
   */
  cachedExtendedDeviations: {
    [key: number]: PapiDeviation;
  };

  /**
   * When a user clicks on the create button, the server will verify the prompt doesn't contain
   * forbidden terms. We need to update the UI while we wait or when errors are returned.
   *
   * e.g. disabling the create button while the request is pending.
   */
  currentSubmission: {
    status: SubmissionStatus;
    error?: SubmissionError;
    /**
     * Some error types returned by the server can apply to a wide variety of circumstances. In those cases,
     * we'll pass the server error message straight through instead of relying on stuff in babel.
     */
    serverErrorMessage?: string;
  };

  /**
   * We display purchase links for points in a variety of amounts. These are those
   * points packages.
   *
   * A server-side value included in redux for ease of use.
   */
  pointsPackages: {
    /**
     * The number of points a user will receive if they purchase this package.
     */
    points: number;

    /**
     * The price of the package in dollars.
     */
    price: number;

    /**
     * The product id of the package that we'll need to pass to checkout.
     */
    subproductId: number;
  }[];

  /**
   * A variety of restrictions that apply to the user's ability to submit prompts. These values are only updated
   * based on server responses, no UI interactions can update them directly.
   */
  restrictions: {
    /**
     * A server-side value included in redux for ease of use.
     */
    canUseFreePrompt: boolean;

    /**
     * A server-side value included in redux for ease of use.
     */
    canUsePaidPrompt: boolean;

    /**
     * The maximum number of free prompts we grant to the user's current core level.
     *
     * A server-side value included in redux for ease of use.
     */
    freePromptsAllowed: number;

    /**
     * The number of free prompts the user has left.
     *
     * A server-side value included in redux for ease of use.
     */
    freePromptsRemaining: number;

    /**
     * Each core level generates a different number of images per prompt. This value reflects that.
     *
     * A server-side value included in redux for ease of use.
     */
    imagesPerPrompt: number;

    /**
     * Each core level has a different price for paid prompts. This value reflects that.
     *
     * A server-side value included in redux for ease of use.
     */
    promptPointsPrice: number;

    /**
     * Some core levels have higher queue priority than others. This value reflects that.
     *
     * A server-side value included in redux for ease of use.
     */
    queuePriority: 'low' | 'high';
  };

  /**
   * We have a burger menu on mobile that needs to be hidden when we push modals.
   *
   * Mobile only.
   */
  isBurgerMenuOpen: boolean;

  /**
   * The MediaPopup for picking a starting image is detached from the button which shows it.
   */
  isStartingImageSelectorOpen: boolean;

  /**
   * The current daily challenge. We show this in hopes the user will want to use Dreamup to submit something for the challenge.
   */
  dailyChallengeTag?: PapiTag;
}

export interface DreamupStoreState {
  [reduxKey]: DreamupStoreSlice;
}
