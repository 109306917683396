import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GrowingTextArea, {
  type GrowingTextAreaProps,
} from '@wix/da-ds/pkg/formControls/lego/GrowingTextArea';
import { SubmissionStatus } from '../../../../../../redux/dreamup/types';
import { getCurrentSubmission } from '../../../../../../redux/dreamup/selectors';
import { FormAction } from '../../../usePromptFormAction';
import {
  useLogInputClickBiEvent,
  useLogInvalidPromptBiEvent,
  usePrompt,
} from './hooks';

interface Props
  extends Omit<
    GrowingTextAreaProps,
    | 'placeholder'
    | 'name'
    | 'value'
    | 'maxRows'
    | 'maxLength'
    | 'onChange'
    | 'onInvalid'
    | 'onClick'
    | 'required'
    | 'disabled'
    | 'autoComplete'
  > {
  formAction: FormAction;
}

const PromptTextArea: React.FC<Props> = ({ formAction, ...rest }) => {
  const { t } = useTranslation();
  const [prompt, handleChange] = usePrompt();

  const currentSubmission = useSelector(getCurrentSubmission);
  const handleInvalid = useLogInvalidPromptBiEvent();
  const handleClick = useLogInputClickBiEvent();

  return (
    <GrowingTextArea
      autoComplete="off"
      placeholder={t('dreamup.header.input.placeholder')}
      name="prompt"
      value={prompt}
      maxRows={6}
      maxLength={400}
      onChange={handleChange}
      onInvalid={handleInvalid}
      onClick={handleClick}
      required={
        formAction === FormAction.SubmitPaidPrompt ||
        formAction === FormAction.SubmitFreePrompt ||
        formAction === FormAction.DisplayPromptGuidelinesModal ||
        formAction === FormAction.AnonJoin
      }
      disabled={currentSubmission.status === SubmissionStatus.AwaitingResponse}
      {...rest}
    />
  );
};

PromptTextArea.displayName = 'PromptTextArea';

export default PromptTextArea;
