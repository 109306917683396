import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { BiData, type RedirectToLoginBiEvent } from '@wix/da-bi/pkg/events';
import DaDreamupLogoAndTextColored from '@wix/da-ds/pkg/DaDreamupLogoAndTextColored';
import BuyButton from '@wix/da-ds/pkg/Buttons/BuyButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import s from './SimpleSiteHeader.scss';

interface Props {}

const SimpleSiteHeader: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div className={s['root']}>
      <DaDreamupLogoAndTextColored view="light" className={s['logo']} />
      <div className={s['buttons']}>
        <TextButton size="large" variant="main" href={Url.loginLink()}>
          {t('dreamupAnon.siteHeader.loginButton.label')}
        </TextButton>
        <BuyButton
          size="large"
          variant="solid"
          rounded
          href={Url.joinLink(undefined, Url.dreamupLink(), undefined, {
            daDreamup: true,
          })}
          biData={BiData<RedirectToLoginBiEvent>({
            evid: 240,
            referral_info: 'header',
          })}
        >
          {t('dreamupAnon.siteHeader.joinButton.label')}
        </BuyButton>
      </div>
    </div>
  );
};

SimpleSiteHeader.displayName = 'SimpleSiteHeader';

export default SimpleSiteHeader;
