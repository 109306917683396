import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { getSimilarity } from '../../../../../redux/dreamup/selectors';
import { changeSimilarity } from '../../../../../redux/dreamup/actions';
import PanelInputField from '../PanelInputField';
import RangeSlider from '../RangeSlider';
import LabelText from '../LabelText';

interface Props {}

const ImageSimilaritySliderField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const similarity = useSelector(getSimilarity);
  const dispatchChangeSimilarity = useAction(changeSimilarity);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      dispatchChangeSimilarity(parseInt(e.target.value));
    },
    [dispatchChangeSimilarity]
  );

  return (
    <PanelInputField
      rootTag="label"
      label={
        <LabelText>
          {t('dreamup.header.settingsPanel.similarity.label')}
        </LabelText>
      }
      input={
        <RangeSlider
          name="similarity"
          value={similarity}
          onChange={handleChange}
          min={1}
          max={100}
          step={1}
          displayValue={`${similarity}%`}
        />
      }
    />
  );
};

ImageSimilaritySliderField.displayName = 'ImageSimilaritySliderField';

export default ImageSimilaritySliderField;
