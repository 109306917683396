import React from 'react';
import PageLayout from '../../shared/PageLayout';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Content from '../Content';
import s from './Page.scss';

interface Props {}

const OldPage: React.FC<Props> = () => {
  return (
    <PageLayout
      sidebar={<Sidebar />}
      className={s['dealer-page']}
      content={
        <div data-testid="app-content" className={s['content-wrapper']}>
          <Header />
          <Content className={s['deviations-area']} />
        </div>
      }
    />
  );
};

OldPage.displayName = 'OldPage';

export default OldPage;
