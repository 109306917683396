import React, { useState } from 'react';
import c from 'classnames';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import MediaIndicator from '@wix/da-ds/pkg/thumbs/MediaIndicator';
import { DumboProps } from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import DeviationFullView from '@wix/da-shared-react/pkg/DeviationViews/Full';
import s from './MeasuredArtView.scss';

interface Props extends Omit<DumboProps, 'width'> {
  className?: string;
}

const MeasuredArtView: React.FC<Props> = ({
  deviation,
  className,
  ...rest
}) => {
  const [isZoomed, setZoomed] = useState(false);
  const { ref, dimensions: containerSize } = useMeasureElementCookie(
    MeasuredCookieType.DREAMUP_INFO_MODAL,
    { cookieDim: 'both' }
  );

  if (containerSize) {
    return (
      <div ref={ref} className={c(s['root'], className)}>
        {deviation.isUpscaled && (
          <MediaIndicator type="hd" className={s['upscaled-indicator']} />
        )}
        <DeviationFullView
          deviation={deviation}
          width={containerSize.width}
          height={containerSize.height}
          useBackgroundColor={false}
          noCropping
          isZoomed={isZoomed}
          onEnterZoomMode={() => setZoomed(true)}
          onExitZoomMode={() => setZoomed(false)}
          {...rest}
        />
      </div>
    );
  } else {
    return null;
  }
};

MeasuredArtView.displayName = 'MeasuredArtView';

export default MeasuredArtView;
