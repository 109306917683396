import React, { HTMLAttributes } from 'react';
import c from 'classnames';
import s from './LabelText.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const LabelText: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <div className={c(s['root'], className)} {...rest}>
      {children}
    </div>
  );
};

LabelText.displayName = 'LabelText';

export default LabelText;
