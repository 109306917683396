import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import {
  BiData,
  BuyCoreButtonBiEvent,
  GetPointsDreamupClickBiEvent,
  RedirectToLoginBiEvent,
} from '@wix/da-bi/pkg/events';
import { setRef } from '@wix/da-ds/pkg/utils/refHelpers';
import BrandedButton, {
  type BrandedButtonProps,
} from '@wix/da-ds/pkg/Buttons/BrandedButton';
import {
  getCurrentSubmission,
  getIsPromptEmpty,
  getPricePerPrompt,
} from '../../../../../../redux/dreamup/selectors';
import { SubmissionStatus } from '../../../../../../redux/dreamup/types';
import { FormAction } from '../../../usePromptFormAction';

/**
 * Our input's button text has several variations based on the state of the redux store. This
 * hook goes through all the trouble of sussing the right variation out.
 *
 * Just a helper to declutter the component code.
 */
function useCreateButtonPropsPerFormAction(
  formAction: FormAction
): Pick<BrandedButtonProps, 'variant' | 'children' | 'disabled' | 'biData'> {
  const { t } = useTranslation();
  const currentPromptSubmission = useSelector(getCurrentSubmission);
  const pricePerPrompt = useSelector(getPricePerPrompt);
  const isPromptEmpty = useSelector(getIsPromptEmpty);

  if (currentPromptSubmission.status === SubmissionStatus.AwaitingResponse) {
    return {
      variant: 'dreamup-purple',
      children: t('dreamup.createButton.label.creating'),
      disabled: true,
    };
  }

  // `formAction` already went through all the checks we'd otherwise do here, so we just need
  // look at it to determine which text to show. This makes it easy to do storybook testing too since
  // we just need to pass in the appropriate `formAction` instead of futzing with the store.

  if (formAction === FormAction.AnonJoin) {
    return {
      variant: 'dreamup-purple',
      children: t('dreamup.createButton.label.hasFreePrompts'),
      biData: isPromptEmpty
        ? undefined
        : BiData<RedirectToLoginBiEvent>({
            evid: 240,
            referral_info: 'anon_dreamup_form',
          }),
    };
  }

  if (
    [
      FormAction.DisplayVerificationModal,
      FormAction.DisplayPromptGuidelinesModal,
      FormAction.SubmitFreePrompt,
    ].includes(formAction)
  ) {
    return {
      variant: 'dreamup-purple',
      children: t('dreamup.createButton.label.hasFreePrompts'),
    };
  }

  if (
    [FormAction.SubmitPaidPrompt, FormAction.DisplayPointsModal].includes(
      formAction
    )
  ) {
    return {
      variant: 'dreamup-purple',
      children: t('dreamup.createButton.label.noFreePrompts.core', {
        count: pricePerPrompt,
      }),
      biData: BiData<GetPointsDreamupClickBiEvent>({
        evid: 918,
        referral_info: 'top_create',
      }),
    };
  }

  return {
    variant:
      formAction === FormAction.DirectToPackagePicker
        ? 'core-orange'
        : 'dreamup-purple',
    children: t('dreamup.createButton.label.noFreePrompts.nonCore'),
    biData: BiData<BuyCoreButtonBiEvent>({
      evid: 375,
      referral_info: ReferralType.DREAMUP_TOP_CREATE,
    }),
  };
}

interface Props
  extends Omit<
    BrandedButtonProps,
    | keyof ReturnType<typeof useCreateButtonPropsPerFormAction>
    | 'size'
    | 'type'
    | 'innerRef'
  > {
  formAction: FormAction;
}

const PromptSubmitButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ formAction, ...restConsumerProps }, ref) => {
    const { children, ...restPresetProps } =
      useCreateButtonPropsPerFormAction(formAction);

    return (
      <BrandedButton
        innerRef={node => setRef(ref, node)}
        type="submit"
        size="large"
        {...restConsumerProps}
        {...restPresetProps}
      >
        {children}
      </BrandedButton>
    );
  }
);

PromptSubmitButton.displayName = 'PromptSubmitButton';

export default PromptSubmitButton;
