import React from 'react';
import ReusePromptIcon from '@wix/da-ds/pkg/Icons/24x24/ReusePrompt';
import CreateVariationsIcon from '@wix/da-ds/pkg/Icons/24x24/CreateVariations';
import DownloadIcon from '@wix/da-ds/pkg/Icons/24x24/Download';
import UpscaleIcon from '@wix/da-ds/pkg/Icons/Expand';
import DeleteIcon from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import { useTranslation } from 'react-i18next';
import type { PapiDeviation } from '@wix/da-papi-types';
import {
  useCopyPromptAction,
  useCreateVariationAction,
  useDownloadAction,
  useDreamupDeleteAction,
  useUpscaleAction,
} from '../../../hooks';
import ActionPanelItem from '../ActionPanelItem';

interface Props {
  deviation: PapiDeviation;
}

const ActionsPanel: React.FC<Props> = ({ deviation }) => {
  const { t } = useTranslation();

  const copyPromptAction = useCopyPromptAction(deviation);
  const createVariationAction = useCreateVariationAction(deviation);
  const upscaleAction = useUpscaleAction(deviation);
  const downloadAction = useDownloadAction(deviation);
  const handleDeleteClick = useDreamupDeleteAction(deviation, {
    biData: {
      sectionname: 'prompt_info',
      component: 'prompt',
    },
  });

  return (
    <div>
      <ActionPanelItem
        icon={ReusePromptIcon}
        label={t('dreamup.modal.promptInfo.actions.copyPrompt.label')}
        disabled={copyPromptAction.disabled}
        onClick={copyPromptAction.onClick}
        biData={copyPromptAction.biData}
      />
      <ActionPanelItem
        icon={CreateVariationsIcon}
        label={t('dreamup.modal.promptInfo.actions.createVariations.label')}
        onClick={createVariationAction.onClick}
        biData={createVariationAction.biData}
        disabled={createVariationAction.disabled}
      />
      <ActionPanelItem
        icon={UpscaleIcon}
        label={t('dreamup.modal.promptInfo.actions.upscale.label')}
        onClick={upscaleAction.onClick}
        disabled={upscaleAction.disabled}
        biData={upscaleAction.biData}
      />
      <ActionPanelItem
        icon={DownloadIcon}
        label={t('dreamup.modal.promptInfo.actions.download.label')}
        href={downloadAction.href}
        disabled={downloadAction.disabled}
        biData={downloadAction.biData}
      />
      <ActionPanelItem
        icon={DeleteIcon}
        label={t('dreamup.modal.promptInfo.actions.delete.label')}
        onClick={handleDeleteClick}
      />
    </div>
  );
};

ActionsPanel.displayName = 'ActionsPanel';

export default ActionsPanel;
