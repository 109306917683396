import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import UpscaleRadioButton, {
  UpscaleRadioButtonProps,
} from '../UpscaleRadioButton';
import CoreRequirementIndicator from '../CoreRequirementIndicator';

import s from './CreativityFactorField.scss';

interface Props extends Pick<UpscaleRadioButtonProps, 'onChange'> {
  currentValue: 1 | 2 | 3 | 4 | 5;
}

const CreativityFactorField: React.FC<Props> = ({ currentValue, onChange }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const lessLabel = (
    <span className={s['less-more-label']}>
      {t('dreamup.modal.upscaleConfirmation.creativityFactorField.lessLabel')}
    </span>
  );
  const moreLabel = (
    <span className={s['less-more-label']}>
      {t('dreamup.modal.upscaleConfirmation.creativityFactorField.moreLabel')}
    </span>
  );

  return (
    <div className={s['root']}>
      {isMobile && (
        <div className={s['mobile-less-more-labels']}>
          {lessLabel}
          {moreLabel}
        </div>
      )}
      {!isMobile && lessLabel}
      <div className={s['radio-button-wrapper']}>
        <div className={s['radio-buttons']}>
          <CoreRequirementIndicator className={s['core-indicator']} />
          {[1, 2, 3, 4, 5].map((value, index) => (
            <UpscaleRadioButton
              key={index}
              name="creativity_factor"
              value={`${value}`}
              checked={value === currentValue}
              onChange={onChange}
              hasAccessibleText
            >
              {value}
            </UpscaleRadioButton>
          ))}
        </div>
      </div>
      {!isMobile && moreLabel}
    </div>
  );
};

CreativityFactorField.displayName = 'CreativityFactorField';

export default CreativityFactorField;
