import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import type { PapiDeviation } from '@wix/da-papi-types';
import { popAllModals } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import {
  getCoreLevel,
  getCurrentUserHasCore,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  getCanSubmitFreePrompts,
  getCanSubmitPaidPrompts,
  getPricePerPrompt,
} from '../../../redux/dreamup/selectors';
import {
  pushPointsModal,
  upscaleDeviation,
} from '../../../redux/dreamup/actions';
import { jumpToDreamupHeader } from '../Header';

/**
 * Indicates what clicking the confirm button on the upscale confirmation modal should do.
 */
export enum UpscaleConfirmationAction {
  SubmitWithPrompt = 'submit-with-prompt',
  SubmitWithPoints = 'submit-with-points',
  UpgradeCore = 'upgrade-core',
  PurchasePoints = 'purchase-points',
}

/**
 * Returns an enum indicating what action the confirmation modal should take when the confirmation modal's
 * confirm button is clicked.
 */
export function useUpscaleConfirmationAction(
  creativityValue: 1 | 2 | 3 | 4 | 5
): UpscaleConfirmationAction {
  const hasCore = useSelector(getCurrentUserHasCore);
  const coreLevel = useSelector(getCoreLevel);
  const canSubmitPaidPrompts = useSelector(getCanSubmitPaidPrompts);
  const canSubmitFreePrompts = useSelector(getCanSubmitFreePrompts);

  if (
    creativityValue >= 4 &&
    !['core_pro', 'core_pro_plus'].includes(coreLevel ?? '')
  ) {
    return UpscaleConfirmationAction.UpgradeCore;
  }

  if (canSubmitFreePrompts) {
    return UpscaleConfirmationAction.SubmitWithPrompt;
  }

  if (!hasCore) {
    return UpscaleConfirmationAction.UpgradeCore;
  }

  if (canSubmitPaidPrompts) {
    return UpscaleConfirmationAction.SubmitWithPoints;
  }

  return UpscaleConfirmationAction.PurchasePoints;
}

export function useConfirmationButtonLabel(
  confirmationAction: UpscaleConfirmationAction
) {
  const { t } = useTranslation();
  const pricePerPrompt = useSelector(getPricePerPrompt);

  switch (confirmationAction) {
    case UpscaleConfirmationAction.SubmitWithPrompt:
      return t(
        // FEATURE CLEANUP: upscale_v2 merge this translation key
        'dreamup.modal.upscaleConfirmationV2.confirmButton.confirmWithPrompt'
      );
    case UpscaleConfirmationAction.SubmitWithPoints:
      return t(
        // FEATURE CLEANUP: upscale_v2 merge this translation key
        'dreamup.modal.upscaleConfirmationV2.confirmButton.confirmWithPoints',
        { cost: pricePerPrompt }
      );
    case UpscaleConfirmationAction.UpgradeCore:
      return t(
        // FEATURE CLEANUP: upscale_v2 merge this translation key
        'dreamup.modal.upscaleConfirmationV2.confirmButton.upgradeToCore'
      );
    case UpscaleConfirmationAction.PurchasePoints:
    default:
      // FEATURE CLEANUP: upscale_v2 merge this translation key
      return t('dreamup.modal.upscaleConfirmationV2.confirmButton.buyPoints', {
        cost: pricePerPrompt,
      });
  }
}

export function useSubmitHandler(
  deviation: PapiDeviation,
  confirmationAction: UpscaleConfirmationAction,
  creativityLevel: 1 | 2 | 3 | 4 | 5
): React.FormEventHandler<HTMLFormElement> {
  const dispatchUpscaleDeviation = useAction(upscaleDeviation);
  const dispatchPushPointsModal = useAction(pushPointsModal);
  const dispatchPopAllModals = useAction(popAllModals);

  return useCallback(
    e => {
      switch (confirmationAction) {
        case UpscaleConfirmationAction.SubmitWithPoints:
          dispatchUpscaleDeviation(deviation, true, creativityLevel);
          dispatchPopAllModals();
          break;
        case UpscaleConfirmationAction.UpgradeCore:
          window.location.href = Url.coreMembershipLink(Url.dreamupLink());
          break;
        case UpscaleConfirmationAction.PurchasePoints:
          dispatchPushPointsModal();
          break;
        case UpscaleConfirmationAction.SubmitWithPrompt:
        default:
          dispatchUpscaleDeviation(deviation, false, creativityLevel);
          dispatchPopAllModals();
          jumpToDreamupHeader();
          break;
      }

      e.preventDefault();
      return false;
    },
    [
      confirmationAction,
      dispatchUpscaleDeviation,
      dispatchPushPointsModal,
      dispatchPopAllModals,
      deviation,
      creativityLevel,
    ]
  );
}
