import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import Faqs from '@wix/da-shared-react/pkg/Faqs';
import { getFaqs } from '../../../../redux/dreamupAnon/selectors';
import s from './FaqSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const FaqSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const faqs = useSelector(getFaqs);

  return (
    <Section className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['sizing-wrapper']}>
        <Heading className={s['heading']}>
          {t('dreamupAnon.faq.heading')}
        </Heading>
        <Faqs faqs={faqs} />
      </div>
    </Section>
  );
};

FaqSection.displayName = 'FaqSection';

export default FaqSection;
