import React from 'react';
import type { PapiDeviation } from '@wix/da-papi-types';
import {
  BiData,
  type DreamupUpscaleConfirmBiEvent,
  type BuyCoreButtonBiEvent,
} from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import {
  UpscaleConfirmationAction,
  useConfirmationButtonLabel,
} from '../useUpscaleConfirmationAction';

function getBiForConfirmationAction(
  confirmationAction: UpscaleConfirmationAction,
  deviation: PapiDeviation,
  creativityValue: number
) {
  switch (confirmationAction) {
    case UpscaleConfirmationAction.UpgradeCore:
      return BiData<BuyCoreButtonBiEvent>({
        evid: 375,
        typeid: deviation.typeId,
        itemid: deviation.deviationId,
        referral_info: ReferralType.UPSCALE_CONFIRMATION,
      });
    case UpscaleConfirmationAction.PurchasePoints:
      return undefined;
    case UpscaleConfirmationAction.SubmitWithPrompt:
    case UpscaleConfirmationAction.SubmitWithPoints:
    default:
      return BiData<DreamupUpscaleConfirmBiEvent>({
        evid: 929,
        prompt: deviation.extended?.dreamsofart?.prompt,
        deviationid: deviation.deviationId,
        creativity: creativityValue,
      });
  }
}

interface Props {
  deviation: PapiDeviation;
  confirmationAction: UpscaleConfirmationAction;
  /**
   * Only used for BI
   */
  creativityValue: number;
}

const UpscaleConfirmationButton: React.FC<Props> = ({
  deviation,
  creativityValue,
  confirmationAction,
}) => {
  const label = useConfirmationButtonLabel(confirmationAction);
  return (
    <BrandedButton
      size="large"
      variant={
        confirmationAction === UpscaleConfirmationAction.UpgradeCore
          ? 'core-orange'
          : 'brand-green'
      }
      biData={getBiForConfirmationAction(
        confirmationAction,
        deviation,
        creativityValue
      )}
      type="submit"
    >
      {label}
    </BrandedButton>
  );
};

UpscaleConfirmationButton.displayName = 'UpscaleConfirmationButton';

export default UpscaleConfirmationButton;
