import React, { useCallback, useContext, useMemo, useState } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { PapiDeviation } from '@wix/da-papi-types';
import {
  BiData,
  DreamupSubmitAsDeviationBiEvent,
  OpenPromptModalBiEvent,
} from '@wix/da-bi/pkg/events';
import { Url } from '@wix/da-url';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import UploadIcon from '@wix/da-ds/pkg/Icons/24x24/Upload';
import TrashcanIcon from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import DownloadIcon from '@wix/da-ds/pkg/Icons/24x24/Download';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import ReusePromptIcon from '@wix/da-ds/pkg/Icons/24x24/ReusePrompt';
import CreateVariationsIcon from '@wix/da-ds/pkg/Icons/24x24/CreateVariations';
import ImageIcon from '@wix/da-ds/pkg/Icons/24x24/Image';
import ExpandIcon from '@wix/da-ds/pkg/Icons/Expand';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import MediaIndicator from '@wix/da-ds/pkg/thumbs/MediaIndicator';
import Dumbo from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import HoverOnly from '@wix/da-ds/pkg/thumbs/OverlayHelpers/HoverOnly';
import ThumbOverlayBackground from '@wix/da-ds/pkg/thumbs/legos/ThumbOverlayBackground';
import ThumbActions from '@wix/da-shared-react/pkg/DeviationViews/Helper/ThumbActions';
import IconButtonWithBackground from '@wix/da-ds/pkg/Buttons/IconButton/IconButtonWithBackground';
import HoverableWrapper from '@wix/da-ds/pkg/thumbs/OverlayHelpers/HoverableWrapper';
import {
  fetchExtendedDeviation,
  pushPromptInfoModal,
} from '../../../redux/dreamup/actions';
import { getPrompt } from '../../../redux/dreamup/selectors';
import {
  useCopyPromptAction,
  useCreateVariationAction,
  useDownloadAction,
  useDreamupDeleteAction,
  useMuroAction,
  useUpscaleAction,
} from '../hooks';
import MuroModal from '../MuroModal';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';

import s from './DreamupThumb.scss';

interface Props {
  deviation: PapiDeviation;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  className?: string;
}

/**
 * A deviation thumbnail prepopulated with the `actions` required by Dreamup.
 */
const DreamupThumb: React.FC<Props> = ({
  deviation,
  width,
  height,
  style,
  className,
}) => {
  const isMuroExp = useFlag('muro_exp');
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const dispatchPushPromptInfoModal = useAction(pushPromptInfoModal);
  const dispatchFetchExtendedDeviation = useAction(fetchExtendedDeviation);
  const upscaleAction = useUpscaleAction(deviation);
  const copyPromptAction = useCopyPromptAction(deviation);
  const muroAction = useMuroAction(deviation);
  const downloadAction = useDownloadAction(deviation);
  const prompt = useSelector(getPrompt);
  const createVariationAction = useCreateVariationAction(deviation);

  const [showMuro, setShowMuro] = useState(false);

  const handlePushDeleteConfirmation = useDreamupDeleteAction(deviation, {
    biData: {
      sectionname: '3_dots',
    },
  });

  const handleThumbClick = useCallback(() => {
    dispatchPushPromptInfoModal(deviation);
  }, [dispatchPushPromptInfoModal, deviation]);

  const handleActionsOpen = useCallback(() => {
    dispatchFetchExtendedDeviation(deviation);
  }, [dispatchFetchExtendedDeviation, deviation]);

  const actions: NullableDropdownItem[] = useMemo(
    () => [
      {
        label: '',
        items: [
          {
            label: t('dreamup.content.deviationThumb.actions.submit'),
            icon: <UploadIcon disableDefaultColors size={IconSize.SMALL} />,
            link: Url.submissionLinkWithId(deviation.deviationId),
            biData: BiData<DreamupSubmitAsDeviationBiEvent>({
              evid: 910,
              sectionname: '3_dots',
              deviationid: deviation.deviationId,
              prompt,
            }),
          },
        ],
      },
      {
        label: '',
        items: [
          {
            icon: <DownloadIcon />,
            label: t('dreamup.content.deviationThumb.actions.download'),
            link: downloadAction.href,
            disabled: downloadAction.disabled,
            biData: downloadAction.biData,
          },
          {
            label: t('dreamup.content.deviationThumb.actions.copyPrompt'),
            icon: (
              <ReusePromptIcon disableDefaultColors size={IconSize.SMALL} />
            ),
            onClick: copyPromptAction.onClick,
            disabled: copyPromptAction.disabled,
            biData: copyPromptAction.biData,
          },
          {
            label: t('dreamup.content.deviationThumb.actions.createVariations'),
            icon: (
              <CreateVariationsIcon
                disableDefaultColors
                size={IconSize.SMALL}
              />
            ),
            onClick: createVariationAction.onClick,
            disabled: createVariationAction.disabled,
            biData: createVariationAction.biData,
          },
          !isMobile && {
            label: muroAction.label,
            icon: <ImageIcon disableDefaultColors size={IconSize.SMALL} />,
            link: isMuroExp ? undefined : muroAction.href,
            onClick: isMuroExp
              ? () => {
                  setShowMuro(true);
                }
              : undefined,
            disabled: muroAction.disabled,
            target: '_blank',
            biData: muroAction.biData,
          },
          {
            label: t('dreamup.content.deviationThumb.actions.upscale'),
            icon: <ExpandIcon disableDefaultColors size={IconSize.SMALL} />,
            onClick: upscaleAction.onClick,
            disabled: upscaleAction.disabled,
            biData: upscaleAction.biData,
          },
          {
            label: t('dreamup.content.deviationThumb.actions.delete'),
            icon: <TrashcanIcon disableDefaultColors size={IconSize.SMALL} />,
            onClick: handlePushDeleteConfirmation,
          },
        ],
      },
    ],
    [
      t,
      deviation.deviationId,
      prompt,
      downloadAction.href,
      downloadAction.disabled,
      downloadAction.biData,
      copyPromptAction.onClick,
      copyPromptAction.disabled,
      copyPromptAction.biData,
      createVariationAction.onClick,
      createVariationAction.disabled,
      createVariationAction.biData,
      isMobile,
      muroAction.label,
      muroAction.href,
      muroAction.disabled,
      muroAction.biData,
      isMuroExp,
      upscaleAction.onClick,
      upscaleAction.disabled,
      upscaleAction.biData,
      handlePushDeleteConfirmation,
    ]
  );

  return (
    <HoverableWrapper
      style={{ width, height, ...style }}
      className={c(s['root'], className)}
    >
      <MuroModal deviation={deviation} isOpen={showMuro} />
      <PlainButtonOrLink
        className={s['thumb-button']}
        onClick={handleThumbClick}
        biData={BiData<OpenPromptModalBiEvent>({
          evid: 921,
          deviationid: deviation.deviationId,
        })}
        isFakeButton
      >
        <Dumbo deviation={deviation} width={width} height={height} />
      </PlainButtonOrLink>
      <HoverOnly>
        <ThumbOverlayBackground type="gradient" className={s['overlay']} />
        <div className={s['title']}>{deviation.title}</div>
      </HoverOnly>
      <div className={s['top-right']}>
        {deviation.isUpscaled && <MediaIndicator type="hd" />}
        <ThumbActions
          deviation={deviation}
          actions={actions}
          onOpen={handleActionsOpen}
        >
          <IconButtonWithBackground
            size="medium"
            icon={EllipsisIcon}
            aria-label={t(
              'dreamup.content.deviationThumb.actions.a11y.moreActions'
            )}
          />
        </ThumbActions>
      </div>
    </HoverableWrapper>
  );
};

DreamupThumb.displayName = 'DreamupThumb';

export default React.memo(DreamupThumb, isEqual);
