import React from 'react';
import c from 'classnames';
import s from './PageLayout.scss';

interface Props {
  sidebar: React.ReactNode;
  content: React.ReactNode;
  isSidebarOpen?: boolean;
  className?: string;
}

const PageLayout: React.FC<Props> = ({
  sidebar,
  content,
  isSidebarOpen,
  className,
}) => {
  return (
    <div className={c(
      s['root'],
      isSidebarOpen && s['open'],
      className
    )}>
      {sidebar}
      {content}
    </div>
  );
};

PageLayout.displayName = 'PageLayout';

export default PageLayout;
