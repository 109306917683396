import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import {
  BiData,
  DreamupClickCreateBiEvent,
  DreamupPromptClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { changePrompt } from '../../../../../../redux/dreamup/actions';
import {
  getArtStyle,
  getAspectRatio,
  getNegativePrompt,
  getPrompt,
  getPromptStrength,
  getSimilarity,
} from '../../../../../../redux/dreamup/selectors';

/**
 * Fetches the user-entered prompt value from redux and returns some functions
 * that interact with it.
 */
export function usePrompt(): [
  string,
  React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
] {
  const dispatchChangePrompt = useAction(changePrompt);
  const prompt = useSelector(getPrompt);

  const handlePromptChange = useCallback(
    e => {
      dispatchChangePrompt(e.target.value.trimStart());
    },
    [dispatchChangePrompt]
  );
  return [prompt, handlePromptChange];
}

/**
 * We only need to dispatch DreamupClickCreateBiEvent from these components when the prompt is invalid. The
 * saga handles the other dispatches.
 */
export function useLogInvalidPromptBiEvent() {
  const logBiEvent = useBiLogger();
  const prompt = useSelector(getPrompt);
  const promptStrength = useSelector(getPromptStrength);
  const imageSimilarity = useSelector(getSimilarity);
  const aspectRatio = useSelector(getAspectRatio);
  const artStyle = useSelector(getArtStyle);
  const negativePrompt = useSelector(getNegativePrompt);

  return useCallback(() => {
    logBiEvent(
      BiData<DreamupClickCreateBiEvent>({
        evid: 912,
        prompt,
        is_valid: false,
        image_similarity: imageSimilarity,
        aspect_ratio: aspectRatio,
        prompt_weight: promptStrength,
        art_style: artStyle,
        negative_prompt: negativePrompt,
      })
    );
  }, [
    logBiEvent,
    prompt,
    promptStrength,
    imageSimilarity,
    aspectRatio,
    artStyle,
    negativePrompt,
  ]);
}

export function useLogInputClickBiEvent() {
  const logBiEvent = useBiLogger();

  return useCallback(() => {
    logBiEvent(
      BiData<DreamupPromptClickBiEvent>({
        evid: 911,
      })
    );
  }, [logBiEvent]);
}
