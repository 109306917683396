import React from 'react';
import c from 'classnames';
import s from './AccountStatusDescription.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AccountStatusDescription: React.FC<Props> = ({ children, className }) => (
  <p className={c(s['root'], className)}>{children}</p>
);

AccountStatusDescription.displayName = 'AccountStatusDescription';

export default AccountStatusDescription;
