import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPointsCount } from '@wix/da-shared-react/pkg/publicSession/selectors';
import FooterLink from '../FooterLink';
import s from './PointsFooter.scss';

interface Props {}

const PointsFooter: React.FC<Props> = () => {
  const { t } = useTranslation();
  const balance = useSelector(getPointsCount);

  return (
    <div className={s['root']}>
      <span className={s['balance']}>
        {t('dreamup.modal.points.card.balance', { count: balance })}
      </span>
      <span className={s['links']}>
        <FooterLink href={t('dreamup.modal.points.footer.termsLink.url')}>
          {t('dreamup.modal.points.footer.termsLink.text')}
        </FooterLink>{' '}
        |{' '}
        <FooterLink href={t('dreamup.modal.points.footer.learnMoreLink.url')}>
          {t('dreamup.modal.points.footer.learnMoreLink.text')}
        </FooterLink>
      </span>
    </div>
  );
};

PointsFooter.displayName = 'PointsFooter';

export default PointsFooter;
