import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { changeNegativePrompt } from '../../../../../redux/dreamup/actions';
import { getNegativePrompt } from '../../../../../redux/dreamup/selectors';
import GrowingTextArea, {
  type GrowingTextAreaProps,
} from '@wix/da-ds/pkg/formControls/lego/GrowingTextArea';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';

interface Props
  extends Omit<GrowingTextAreaProps, 'value' | 'onChange' | 'placeholder'> {}

const NegativePromptTextArea: React.FC<Props> = props => {
  const { t } = useTranslation();
  const dispatchChangeNegativePrompt = useAction(changeNegativePrompt);
  const negativePrompt = useSelector(getNegativePrompt);
  return (
    <label>
      <VisuallyHidden>
        {t('dreamup.header.settingsPanel.negativePromptInput.a11y.label')}
      </VisuallyHidden>
      <GrowingTextArea
        placeholder={t(
          'dreamup.header.settingsPanel.negativePromptInput.placeholder'
        )}
        value={negativePrompt}
        maxLength={400}
        onChange={e => {
          dispatchChangeNegativePrompt(e.target.value.trimStart());
        }}
        {...props}
      />
    </label>
  );
};

NegativePromptTextArea.displayName = 'NegativePromptTextArea';

export default NegativePromptTextArea;
