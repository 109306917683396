import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { ThemeSurface } from '@wix/da-react-context/pkg/constants';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import {
  ModalBody,
  ModalRoot,
  ModalRootProps,
} from '@wix/da-ds/pkg/modals/base';
import { useNormalizedDeviation } from '@wix/da-shared-react/pkg/utils/hooks/useNormalizedDeviation';
import { PopupContext } from '@wix/da-ds/pkg/popups/PopupContext';
import MobileContent from './MobileContent';
import DesktopContent from './DesktopContent';

import s from './InfoModal.scss';

interface Props extends Omit<ModalRootProps, 'isOpen' | 'isFullscreen'> {
  deviation: PapiDeviation;
}

// Separate body component to make use of ModalRoot's contexts
const Body: React.FC<{ deviation: PapiDeviation }> = ({ deviation }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { setDefaultScrollableRef } = useContext(PopupContext);

  return (
    <ModalBody
      surfaceType={ThemeSurface.TERTIARY}
      className={s['body']}
      ref={setDefaultScrollableRef}
      isFullscreen={isMobile}
    >
      <BiLoggerContextProvider
        value={{ sectionname: 'prompt_info', component: 'prompt' }}
      >
        <div className={cn(s['content-root'])}>
          <VisuallyHidden>
            <Heading>{t('dreamup.modal.promptInfo.heading')}</Heading>
          </VisuallyHidden>
          {isMobile ? (
            <MobileContent deviation={deviation} />
          ) : (
            <DesktopContent deviation={deviation} />
          )}
        </div>
      </BiLoggerContextProvider>
    </ModalBody>
  );
};

const InfoModal: React.FC<Props> = ({
  deviation: sparseDeviation,
  onRequestClose,
  ...rest
}) => {
  // `sparseDeviation` is already in the info modal redux, but the component doesn't know that.
  // So, using sparseDeviation as a backup value to appease typescript.
  const deviation =
    useNormalizedDeviation(sparseDeviation.deviationId) ?? sparseDeviation;

  const handleClose = useCallback(() => {
    onRequestClose && onRequestClose(null as any);
  }, [onRequestClose]);

  return (
    <ModalRoot
      className={s['root']}
      isOpen
      isFullscreen
      onRequestClose={handleClose}
      {...rest}
    >
      <Body deviation={deviation} />
    </ModalRoot>
  );
};

InfoModal.displayName = 'InfoModal';

export default InfoModal;
