import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import LozengeRadioButton from '@wix/da-ds/pkg/Lozenge/dsPresets/LozengeRadioButton';
import { changeAspectRatio } from '../../../../../redux/dreamup/actions';
import { getAspectRatio } from '../../../../../redux/dreamup/selectors';
import { DreamupStoreSlice } from '../../../../../redux/dreamup/types';
import { ratios } from '../constants';
import LabelText from '../LabelText';
import PanelInputField from '../PanelInputField';
import s from './AspectRatioRadioField.scss';

interface Props {}

const AspectRatioRadioField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const groupLabelId = useUniqueId();

  const aspectRatio = useSelector(getAspectRatio);
  const dispatch = useDispatch();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      dispatch(
        changeAspectRatio(
          e.target.value as DreamupStoreSlice['formValues']['aspectRatio']
        )
      );
    },
    [dispatch]
  );

  return (
    <PanelInputField
      rootTag="div"
      role="radiogroup"
      aria-labelledby={groupLabelId}
      label={
        <LabelText id={groupLabelId}>
          {t('dreamup.header.settingsPanel.aspectRatio.label')}
        </LabelText>
      }
      input={
        <div className={s['radio-buttons']}>
          {ratios.map(ratio => (
            <div key={ratio.value} className={s['radio-button']}>
              <LozengeRadioButton
                name="aspectRatio"
                value={ratio.value}
                className={s['radio-button-component']}
                checked={ratio.value === aspectRatio}
                onChange={handleChange}
                selectedClassName={s['selected']}
                hasAccessibleText
              >
                <div className={s['radio-button-content']}>
                  <div className={ratio.prefixClassName} aria-hidden={true} />
                  <div>{t(ratio.label)}</div>
                </div>
              </LozengeRadioButton>
            </div>
          ))}
        </div>
      }
    />
  );
};

AspectRatioRadioField.displayName = 'AspectRatioRadioField';

export default AspectRatioRadioField;
