import React from 'react';
import { useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import { BiData, type TopNavClickBiEvent } from '@wix/da-bi/pkg/events';
import { ListItemLink } from '@wix/da-ds/pkg/List/ListItem';

interface Props {}

const ContactLinkListItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <ListItemLink
      label={t('sidebar.links.contact')}
      href={URLS.HELP_NEW_TICKET}
      biData={BiData<TopNavClickBiEvent>({
        evid: 700,
        link_name: t('sidebar.links.contact'),
        link_url: URLS.HELP_NEW_TICKET,
      })}
    />
  );
};

ContactLinkListItem.displayName = 'ContactLinkListItem';

export default ContactLinkListItem;
