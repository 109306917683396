import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import MainToggle, {
  type Props as MainToggleProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/MainToggle';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { getIsNegativePromptInputVisible } from '../../../../../redux/dreamup/selectors';
import { toggleNegativePrompt } from '../../../../../redux/dreamup/actions';
import LabelText from '../LabelText';
import s from './NegativePromptToggle.scss';

interface Props extends Pick<MainToggleProps, 'size'> {}

const NegativePromptToggle: React.FC<Props> = ({ size }) => {
  const { t } = useTranslation();
  const labelId = useUniqueId();
  const dispatchToggleNegativePrompt = useAction(toggleNegativePrompt);
  const isNegativeInputPromptVisible = useSelector(
    getIsNegativePromptInputVisible
  );

  return (
    <label id={labelId} className={s['root']}>
      <LabelText>
        {t('dreamup.header.settingsPanel.negativePromptToggle.label')}
      </LabelText>
      <MainToggle
        variant="dreamup-purple"
        value={isNegativeInputPromptVisible}
        onChange={dispatchToggleNegativePrompt}
        aria-labelledby={labelId}
        size={size}
        type="button"
      />
    </label>
  );
};

NegativePromptToggle.displayName = 'NegativePromptToggle';

export default NegativePromptToggle;
