import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import DaDreamupLogoAndTextColored from '@wix/da-ds/pkg/DaDreamupLogoAndTextColored';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  ThemeOverride,
  ThemeOptions,
} from '@wix/da-react-context/pkg/ThemeContext';
import { usePromptFormAction } from '../../PromptForm/usePromptFormAction';
import PromptTextArea from '../../PromptForm/PromptInputV2/lego/PromptTextArea';
import PromptSubmitButton from '../../PromptForm/PromptInputV2/lego/PromptSubmitButton';
import ArtStyleSlider from '../../PromptForm/SettingsPanel/ArtStyleField/ArtStyleSlider';
import s from './FormSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const FormSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [handleSubmit, formAction] = usePromptFormAction();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
    useCallback(
      e => {
        if (e.key === 'Enter') {
          if (submitButtonRef.current) {
            submitButtonRef.current.click();
          }
          e.preventDefault();
        }
      },
      [submitButtonRef]
    );

  return (
    <div className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['heading-and-description']}>
        <h1 className={s['heading']}>
          {t('dreamupAnon.form.heading', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            dreamup: (
              <DaDreamupLogoAndTextColored view="light" className={s['logo']} />
            ),
          })}
        </h1>
        <p className={s['description']}>{t('dreamupAnon.form.description')}</p>
      </div>
      <form className={s['form']} onSubmit={handleSubmit}>
        <ThemeOverride theme={ThemeOptions.LIGHT}>
          <div className={s['text-input-and-button']}>
            <PromptTextArea
              formAction={formAction}
              onKeyDown={handleKeyDown}
              minRows={isMobile ? 2 : 1}
            />
            {!isMobile && (
              <PromptSubmitButton formAction={formAction} ref={submitButtonRef} />
            )}
          </div>
        </ThemeOverride>

        <ArtStyleSlider gradient="primary" />
        {isMobile && (
          <PromptSubmitButton formAction={formAction} ref={submitButtonRef} />
        )}
      </form>
      <p className={s['footnote']}>{t('dreamupAnon.form.footnote')}</p>
    </div>
  );
};

FormSection.displayName = 'FormSection';

export default FormSection;
