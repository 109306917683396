import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import OldDreamupLogo from '../../../shared/sidebar/OldDreamupLogo';
import s from './OldDreamupLogoBlock.scss';

interface Props {
  className?: string;
}

const OldDreamupLogoBlock: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={c(s['root'], className)}>
      <OldDreamupLogo className={s['logo']} />
      <span className={s['blurb']}>
        {t('dreamup.sidebar.links.dreamupZone')}
      </span>
    </div>
  );
};

OldDreamupLogoBlock.displayName = 'OldDreamupLogoBlock';

export default OldDreamupLogoBlock;
