import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import DaDreamupLogoAndTextColored from '@wix/da-ds/pkg/DaDreamupLogoAndTextColored';
import s from './CallToActionSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const CallToActionSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Section className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['sizing-wrapper']}>
        <Heading
          className={s['heading']}
          aria-label={t('dreamupAnon.callToAction.button.heading.a11y')}
        >
          {t('dreamupAnon.callToAction.button.heading', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            dreamup: (
              <DaDreamupLogoAndTextColored view="light" className={s['logo']} />
            ),
          })}
        </Heading>
        <BrandedButton size="large" variant="dreamup-purple" href="#">
          {t('dreamupAnon.callToAction.button.label')}
        </BrandedButton>
      </div>
      <div role="presentation" className={s['gradient-top-right']} />
      <div role="presentation" className={s['gradient-bottom-left']} />
    </Section>
  );
};

CallToActionSection.displayName = 'CallToActionSection';

export default CallToActionSection;
