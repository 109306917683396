import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { Nav } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { Url, URLS } from '@wix/da-url';
import DaDreamupLogoAndTextColored from '@wix/da-ds/pkg/DaDreamupLogoAndTextColored';
import UnderlinedTab from '@wix/da-ds/pkg/Tabs/dsPresets/UnderlinedTabs/UnderlinedTab';
import IconButtonWithContextMenuV2 from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenuV2';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import Ellipsis from '@wix/da-ds/pkg/Icons/Ellipsis';
import LegalUrlsBlock from '../../Sidebar/LegalUrlsBlock';
import SiteHeaderPlacement from '@wix/da-shared-react/pkg/DealerAd/Placement/SiteHeader';
import { BiData, type TopNavClickBiEvent } from '@wix/da-bi/pkg/events';

import s from './SiteHeader.scss';

interface Props {
  className?: string;
}

const SiteHeader: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Nav className={c(s['root'], className)}>
      <VisuallyHidden>
        <Heading>{t('dreamup.siteHeader.a11y.heading')}</Heading>
      </VisuallyHidden>

      <DaDreamupLogoAndTextColored
        className={s['logo']}
        // Hidden because of the heading above
        aria-hidden={true}
      />

      <div className={s['right']}>
        <SiteHeaderPlacement />
        <div className={s['tab-links']}>
          <UnderlinedTab
            label={t('dreamup.siteHeader.links.create')}
            isActive
            href={Url.dreamupLink()}
            size="height-responsive"
            biData={BiData<TopNavClickBiEvent>({
              evid: 700,
              link_name: t('dreamup.siteHeader.links.create'),
              link_url: Url.dreamupLink(),
            })}
          />
          <UnderlinedTab
            label={t('dreamup.siteHeader.links.sell')}
            href={Url.to(URLS.SHOP_ME)}
            size="height-responsive"
            biData={BiData<TopNavClickBiEvent>({
              evid: 700,
              link_name: t('dreamup.siteHeader.links.sell'),
              link_url: Url.to(URLS.SHOP_ME),
            })}
          />
          <UnderlinedTab
            label={t('dreamup.siteHeader.links.muro')}
            href={Url.muroLink()}
            size="height-responsive"
            biData={BiData<TopNavClickBiEvent>({
              evid: 700,
              link_name: t('dreamup.siteHeader.links.muro'),
              link_url: Url.muroLink(),
            })}
          />
          <UnderlinedTab
            label={t('dreamup.siteHeader.links.explore')}
            href={Url.tagsPageLink('dreamup')}
            size="height-responsive"
            biData={BiData<TopNavClickBiEvent>({
              evid: 700,
              link_name: t('dreamup.siteHeader.links.explore'),
              link_url: Url.tagsPageLink('dreamup'),
            })}
          />
        </div>
        <IconButtonWithContextMenuV2
          items={[
            {
              items: [
                {
                  label: t('sidebar.links.backToDa'),
                  link: Url.browseHomeLink(),
                  biData: BiData<TopNavClickBiEvent>({
                    evid: 700,
                    link_name: t('sidebar.links.backToDa'),
                    link_url: Url.browseHomeLink(),
                  }),
                },
                {
                  label: t('sidebar.links.faqs'),
                  link: 'https://www.deviantartsupport.com/en/dreamup',
                  target: '_blank',
                  biData: BiData<TopNavClickBiEvent>({
                    evid: 700,
                    link_name: t('sidebar.links.faqs'),
                    link_url: 'https://www.deviantartsupport.com/en/dreamup',
                  }),
                },
                {
                  label: t('sidebar.links.contact'),
                  link: URLS.HELP_NEW_TICKET,
                  biData: BiData<TopNavClickBiEvent>({
                    evid: 700,
                    link_name: t('sidebar.links.contact'),
                    link_url: URLS.HELP_NEW_TICKET,
                  }),
                },
              ],
            },
            { items: [{ label: '', complexLabel: <LegalUrlsBlock /> }] },
          ]}
          size={IconSize.MEDIUM}
          icon={Ellipsis}
        />
      </div>
    </Nav>
  );
};

SiteHeader.displayName = 'SiteHeader';

export default SiteHeader;
