export const XHR_BASE_PATH = '/_napi/da-stash/';
export const SHARED_API_BASE_PATH = `${XHR_BASE_PATH}shared_api`;
export const ROOT_FOLDER_NAME = '108ae5cf0d4c8491596000c1806158e0';

export const AI_ART_FOLDER_NAME = 'DreamUp';
export const enum DreamupModals {
  POINTS = 'points',
  PROMPT_GUIDELINES = 'prompt_guidelines',
  PROMPT_INFO = 'prompt_info',
  IMAGE_RIGHTS = 'image_rights',
  UPSCALE_CONFIRMATION = 'upscale_confirmation',
}
