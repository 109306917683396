import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import SelectFieldContent from '@wix/da-ds/pkg/Dropdown/select/SelectField/SelectFieldContent';
import { getPromptStrength } from '../../../../../redux/dreamup/selectors';
import { changePromptStrength } from '../../../../../redux/dreamup/actions';
import PanelInputField from '../PanelInputField';
import LabelText from '../LabelText';

interface Props {}

const items = Array.from(Array(21).keys()).map((_, index) => ({
  label: index,
  value: index,
}));

const PromptStrengthSelectField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const strength = useSelector(getPromptStrength);
  const dispatchChangePromptStrength = useAction(changePromptStrength);

  const handleChange = useCallback(
    value => {
      dispatchChangePromptStrength(parseInt(value));
    },
    [dispatchChangePromptStrength]
  );

  return (
    <PanelInputField
      rootTag="label"
      htmlFor="promptStrength"
      label={
        <LabelText>
          {t('dreamup.header.settingsPanel.promptStrength.label')}
        </LabelText>
      }
      input={
        <SelectFieldContent
          id="prompt-strength-select"
          name="promptStrength"
          onSelect={handleChange}
          items={items}
          value={strength}
          withBlankOption={false}
        />
      }
    />
  );
};

PromptStrengthSelectField.displayName = 'PromptStrengthSelectField';

export default PromptStrengthSelectField;
