import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { PapiDeviation } from '@wix/da-papi-types';
import DSModal, {
  type ModalProps,
} from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import { CancelButton } from '@wix/da-ds/pkg/modals/dsPresets/MessageModal/buttons';
import PromptCountAndPointsBalance from './PromptCountAndPointsBalance';
import {
  useSubmitHandler,
  useUpscaleConfirmationAction,
} from './useUpscaleConfirmationAction';
import UpscaleConfirmationButton from './UpscaleConfirmationButton';
import CreativityFactorField from './CreativityFactorField';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

import s from './UpscaleConfirmationModal.scss';

interface Props extends Pick<ModalProps, 'onRequestClose'> {
  deviation: PapiDeviation;
}

const UpscaleConfirmationModal: React.FC<Props> = ({
  deviation,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [creativityValue, setCreativityValue] = useState<1 | 2 | 3 | 4 | 5>(2);
  const upscaleConfirmationAction =
    useUpscaleConfirmationAction(creativityValue);

  const handleCreativityChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(e => {
      if (e.target.checked) {
        setCreativityValue(parseInt(e.target.value) as 1 | 2 | 3 | 4 | 5);
      }
    }, []);

  const handleSubmit = useSubmitHandler(
    deviation,
    upscaleConfirmationAction,
    creativityValue
  );

  return (
    <DSModal
      isOpen
      isFullscreen={false}
      onRequestClose={onRequestClose}
      className={s['root']}
      // FEATURE CLEANUP: merge this translation key
      heading={t('dreamup.modal.upscaleConfirmationV2.title')}
      footerPadding={isMobile ? 'horizontally-less' : 'default'}
      onSubmit={handleSubmit}
      footerRight={[
        <CancelButton key="cancel" onClick={onRequestClose} type="button">
          {t('dreamup.modal.upscaleConfirmation.cancelButton')}
        </CancelButton>,
        <UpscaleConfirmationButton
          key="confirm"
          confirmationAction={upscaleConfirmationAction}
          creativityValue={creativityValue}
          deviation={deviation}
        />,
      ]}
      showFooterBorder
      showHeaderBorder
    >
      <div className={s['content-root']}>
        <div>{t('dreamup.modal.upscaleConfirmation.description')}</div>

        <CreativityFactorField
          currentValue={creativityValue}
          onChange={handleCreativityChange}
        />

        <PromptCountAndPointsBalance
          confirmationAction={upscaleConfirmationAction}
        />
      </div>
    </DSModal>
  );
};

UpscaleConfirmationModal.displayName = 'UpscaleConfirmationModal';

export default UpscaleConfirmationModal;
