import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import s from './RootLayout.scss';

interface Props {
  heading: React.ReactNode;
  description: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const RootLayout: React.FC<Props> = ({
  heading,
  description,
  footer,
  className,
}) => (
  <Section className={c(s['root'], className)}>
    <div className={s['heading-and-description']}>
      {heading}
      {description}
    </div>
    {footer && <div className={s['footer']}>{footer}</div>}
  </Section>
);

RootLayout.displayName = 'RootLayout';

export default RootLayout;
