import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiData, type TopNavClickBiEvent } from '@wix/da-bi/pkg/events';
import { ListItemLink } from '@wix/da-ds/pkg/List/ListItem';

interface Props {}

const FaqLinkListItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <ListItemLink
      label={t('sidebar.links.faqs')}
      href="https://www.deviantartsupport.com/en/dreamup"
      target="_blank"
      biData={BiData<TopNavClickBiEvent>({
        evid: 700,
        link_name: t('sidebar.links.faqs'),
        link_url: 'https://www.deviantartsupport.com/en/dreamup',
      })}
    />
  );
};

FaqLinkListItem.displayName = 'FaqLinkListItem';

export default FaqLinkListItem;
