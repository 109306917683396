import React, { useId } from 'react';
import IconWrap, { Props } from '@wix/da-ds/pkg/Icons/IconWrap';

const DreamupStar: React.FC<Props> = props => {
  const gradientId = useId();
  return (
    <IconWrap aria-hidden="true" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
      >
        <path
          fill={`url(#${gradientId})`}
          d="M0 12c6.181.94 8.249 3.133 8.249 3.133C10.843 17.374 11.993 24 11.993 24h.014s1.185-6.605 3.744-8.867c0 0 2.068-2.193 8.249-3.132V12c-6.181-.94-8.249-3.133-8.249-3.133C13.191 6.606 12.007 0 12.007 0h-.014s-1.15 6.626-3.744 8.867c0 0-2.068 2.193-8.249 3.133z"
        ></path>
        <defs>
          <linearGradient
            id={gradientId}
            x1="0"
            x2="24"
            y1="12"
            y2="12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DA499B"></stop>
            <stop offset="1" stopColor="#5238F2"></stop>
          </linearGradient>
        </defs>
      </svg>
    </IconWrap>
  );
};

DreamupStar.displayName = 'DreamupStar';

export default DreamupStar;
