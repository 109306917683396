import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url, URLS } from '@wix/da-url';
import { BiData, type TopNavClickBiEvent } from '@wix/da-bi/pkg/events';
import { ListItemLink } from '@wix/da-ds/pkg/List/ListItem';
import BetaBadge from '@wix/da-ds/pkg/BetaBadge';

interface Props {}

const SellYourArtListItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <ListItemLink
      label={t('dreamup.sidebar.links.sell')}
      href={Url.to(URLS.SHOP_ME)}
      target="_blank"
      suffix={<BetaBadge badgeType="new" size="medium" variant="purple" />}
      biData={BiData<TopNavClickBiEvent>({
        evid: 700,
        link_name: t('dreamup.sidebar.links.sell'),
        link_url: Url.to(URLS.SHOP_ME),
      })}
    />
  );
};

SellYourArtListItem.displayName = 'SellYourArtListItem';

export default SellYourArtListItem;
