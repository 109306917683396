import React, { useCallback } from 'react';
import first from 'lodash/first';
import { PapiDeviation } from '@wix/da-papi-types';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import MediaSelector from '@wix/da-shared-react/pkg/MediaPopup/MediaSelector';
import { MediaSourceType } from '@wix/da-shared-react/pkg/MediaPopup';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import {
  startVariation,
  hideStartingImageSelector,
} from '../../../../redux/dreamup/actions';
import { VariationSource } from '../../../../redux/dreamup/types';

interface Props {
  isOpen: boolean;
}

/**
 * Contains the media selector opened by {@link StartingImageButton}.
 *
 * Note: This would logically reside within the associated button, but the
 * Selector contains an InputField and click events thereon would bubble up to
 * the StartingImageButton's parent (an InputField), stealing focus. [T62728]
 */
const StartingImageSelector: React.FC<Props> = ({ isOpen }) => {
  const dispatchStartVariation = useAction(startVariation);
  const dispatchHideSelector = useAction(hideStartingImageSelector);

  const handleDeviationSelected = useCallback(
    (deviations: PapiDeviation[]) => {
      const deviation = first(deviations);
      if (deviation) {
        dispatchStartVariation(deviation, {
          variationSource: VariationSource.MediaSelector,
        });
      }
      dispatchHideSelector();
    },
    [dispatchStartVariation, dispatchHideSelector]
  );

  const handleSelectionCancelled = useCallback(() => {
    dispatchHideSelector();
  }, [dispatchHideSelector]);

  return isOpen ? (
    <MediaSelector
      selectionLimit={1}
      mediaSourceTypes={[MediaSourceType.GALLERY, MediaSourceType.STASH]}
      allowedDeviationTypes={[DeviationTypes.IMAGE]}
      onClose={handleSelectionCancelled}
      onSelect={handleDeviationSelected}
      allowUploads
    />
  ) : null;
};

StartingImageSelector.displayName = 'StartingImageSelector';

export default StartingImageSelector;
