import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectFieldContent from '@wix/da-ds/pkg/Dropdown/select/SelectField/SelectFieldContent';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { getAspectRatio } from '../../../../../redux/dreamup/selectors';
import { changeAspectRatio } from '../../../../../redux/dreamup/actions';
import { ratios } from '../constants';
import PanelInputField from '../PanelInputField';
import LabelText from '../LabelText';
import s from './AspectRatioSelectField.scss';

interface Props {}

const AspectRatioSelectField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatchChangeAspectRatio = useAction(changeAspectRatio);
  const aspectRatio = useSelector(getAspectRatio);

  return (
    <PanelInputField
      rootTag="label"
      htmlFor="aspectRatio"
      label={
        <LabelText>
          {t('dreamup.header.settingsPanel.aspectRatio.label')}
        </LabelText>
      }
      input={
        <SelectFieldContent
          id="prompt-aspect-ratio"
          name="aspectRatio"
          onSelect={dispatchChangeAspectRatio}
          items={ratios.map(ratio => ({
            label: t(ratio.label),
            complexLabel: (
              <div className={s['current-value-label']}>
                <div className={ratio.prefixClassName} />
                <span>{t(ratio.label)}</span>
              </div>
            ),
            value: ratio.value,
          }))}
          value={aspectRatio}
          withBlankOption={false}
          allowComplexLabelOnSelected
        />
      }
    />
  );
};

AspectRatioSelectField.displayName = 'AspectRatioSelectField';

export default AspectRatioSelectField;
