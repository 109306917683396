import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GetPointsDreamupClickBiEvent, BiData } from '@wix/da-bi/pkg/events';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import {
  closeBurgerMenu,
  pushPointsModal,
} from '../../../../redux/dreamup/actions';
import s from './GetMorePointsButton.scss';

interface Props {
  onClick?: () => void;
}

const GetMorePointsButton: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(pushPointsModal());
    // Modals will appear underneath the burger menu, so let's make sure it's
    // closed.
    dispatch(closeBurgerMenu());
  }, [dispatch]);

  return (
    <PlainButtonOrLink
      className={s['root']}
      onClick={handleClick}
      biData={BiData<GetPointsDreamupClickBiEvent>({
        evid: 918,
        referral_info: 'left_bar',
      })}
    >
      {t('dreamup.accountStatus.pointsBalance.getMore')}
    </PlainButtonOrLink>
  );
};

GetMorePointsButton.displayName = 'GetMorePointsButton';

export default GetMorePointsButton;
