import omit from 'lodash/omit';
import { handleActions } from 'redux-actions';
import { dreamupGenerationFailed } from '@wix/da-shared-react/pkg/redux/dreamup/actions';
import { getDreamupPrompt } from '@wix/da-shared-react/pkg/types/deviation/getters';
import {
  changeArtStyle,
  changeAspectRatio,
  changePrompt,
  changePromptStrength,
  changeSimilarity,
  clearPrompt,
  clearVariation,
  closeBurgerMenu,
  extendedDeviationRequestSucceeded,
  generateFromPrompt,
  generationFromPromptInitiationFailed,
  generationFromPromptInitiationSucceeded,
  upscaleInitiationSucceeded,
  hidePromptSettingsPanel,
  initializeDreamup,
  showPromptSettingsPanel,
  startVariation,
  toggleBurgerMenu,
  showStartingImageSelector,
  hideStartingImageSelector,
  generatedDeviationsLoaded,
  changeNegativePrompt,
  toggleNegativePrompt,
} from './actions';
import { reduxKey } from './constants';
import {
  ArtStyle,
  DreamupStoreSlice,
  GenerationStatus,
  SubmissionStatus,
  VariationSource,
} from './types';

const defaultFormValues: DreamupStoreSlice['formValues'] = {
  aspectRatio: '1:1',
  similarity: 50,
  prompt: '',
  promptStrength: 7,
  artStyle: ArtStyle.Default,
  isNegativePromptInputVisible: false,
  negativePrompt: '',
};

const reducers = handleActions<DreamupStoreSlice, any>(
  {
    [`${initializeDreamup}`]: (
      state,
      { payload: { data, options } }: ReturnType<typeof initializeDreamup>
    ) => ({
      ...state,
      restrictions: data.restrictions,
      pointsPackages: data.pointsPackages.map(pointsPackage => ({
        points: pointsPackage.pricePoints,
        price: pointsPackage.priceDollars,
        subproductId: pointsPackage.subproductId,
      })),
      generationJobs: data.jobs.reduce(
        (accumulator, currentJob) => ({
          ...accumulator,
          [currentJob.generationId]: {
            uuid: currentJob.generationId,
            imageCount: currentJob.imageCount,
            status: GenerationStatus.Processing,
          },
        }),
        {}
      ),
      dailyChallengeTag: data.dailyChallengeTag,
      isPromptSettingsPanelVisible: options.expandSettings,
      shouldPullFormValuesFromLocalStorage: options.persistFromStorage,
      formValues: {
        ...state.formValues,
        // The only default returned by the backend is `promptStrength`.
        // (Backend calls it `guidance_scale`, if you need to find it)
        promptStrength: data.defaultFormValues?.promptStrength
          ? data.defaultFormValues?.promptStrength
          : state.formValues.promptStrength,
        prompt: options.initialPrompt,
      },
    }),
    [`${changePrompt}`]: (state, action: ReturnType<typeof changePrompt>) => ({
      ...state,
      formValues: {
        ...state.formValues,
        prompt: action.payload.prompt,
      },
    }),
    [`${toggleNegativePrompt}`]: state => ({
      ...state,
      formValues: {
        ...state.formValues,
        negativePrompt: '',
        isNegativePromptInputVisible:
          !state.formValues.isNegativePromptInputVisible,
      },
    }),
    [`${changeNegativePrompt}`]: (
      state,
      action: ReturnType<typeof changeNegativePrompt>
    ) => ({
      ...state,
      formValues: {
        ...state.formValues,
        negativePrompt: action.payload.negativePrompt,
      },
    }),
    [`${clearPrompt}`]: state => ({
      ...state,
      formValues: {
        ...state.formValues,
        prompt: '',
      },
    }),
    [`${changeSimilarity}`]: (
      state,
      action: ReturnType<typeof changeSimilarity>
    ) => ({
      ...state,
      formValues: {
        ...state.formValues,
        similarity: action.payload.similarity,
      },
    }),
    [`${changePromptStrength}`]: (
      state,
      action: ReturnType<typeof changePromptStrength>
    ) => ({
      ...state,
      formValues: {
        ...state.formValues,
        promptStrength: action.payload.promptStrength,
      },
    }),
    [`${changeAspectRatio}`]: (
      state,
      action: ReturnType<typeof changeAspectRatio>
    ) => ({
      ...state,
      formValues: {
        ...state.formValues,
        aspectRatio: action.payload.aspectRatio,
      },
    }),
    [`${changeArtStyle}`]: (
      state,
      action: ReturnType<typeof changeArtStyle>
    ) => ({
      ...state,
      formValues: {
        ...state.formValues,
        artStyle: action.payload.artStyle,
      },
    }),
    [`${startVariation}`]: (
      state,
      action: ReturnType<typeof startVariation>
    ) => ({
      ...state,
      formValues: {
        ...state.formValues,
        // Our AI does not support alternate aspect ratios for variations
        aspectRatio: defaultFormValues.aspectRatio,
        prompt:
          action.payload.options.variationSource === VariationSource.Dreamup
            ? getDreamupPrompt(action.payload.deviation) ?? ''
            : state.formValues.prompt,
      },
      variationTarget: action.payload.deviation,
      variationSource: action.payload.options.variationSource,
    }),
    [`${clearVariation}`]: state => ({
      ...state,
      formValues: {
        ...state.formValues,
        prompt:
          state.variationSource === VariationSource.Dreamup
            ? ''
            : state.formValues.prompt,
      },
      variationTarget: undefined,
      variationSource: undefined,
    }),
    [`${showPromptSettingsPanel}`]: state => ({
      ...state,
      isPromptSettingsPanelVisible: true,
    }),
    [`${hidePromptSettingsPanel}`]: state => ({
      ...state,
      isPromptSettingsPanelVisible: false,
    }),
    [`${generateFromPrompt}`]: state => ({
      ...state,
      currentSubmission: { status: SubmissionStatus.AwaitingResponse },
    }),
    [`${generationFromPromptInitiationSucceeded}`]: (
      state,
      action: ReturnType<typeof generationFromPromptInitiationSucceeded>
    ) => ({
      ...state,
      formValues: {
        ...defaultFormValues,
        prompt: state.formValues.prompt,
        artStyle: state.formValues.artStyle,
        promptStrength: state.formValues.promptStrength,
        aspectRatio: state.formValues.aspectRatio,
      },
      variationTarget: state.variationTarget,
      currentSubmission: { status: SubmissionStatus.Idle },
      generationJobs: {
        ...state.generationJobs,
        [action.payload.generationId]: {
          uuid: action.payload.generationId,
          imageCount: action.payload.imageCount,
          status: GenerationStatus.Processing,
        },
      },
      restrictions: {
        ...action.payload.restrictions,
      },
    }),
    [`${generationFromPromptInitiationFailed}`]: (
      state,
      action: ReturnType<typeof generationFromPromptInitiationFailed>
    ) => ({
      ...state,
      currentSubmission: {
        status: SubmissionStatus.Error,
        error: action.payload.error,
        serverErrorMessage: action.payload.serverErrorMessage,
      },
    }),
    [`${upscaleInitiationSucceeded}`]: (
      state,
      action: ReturnType<typeof upscaleInitiationSucceeded>
    ) => ({
      ...state,
      generationJobs: {
        ...state.generationJobs,
        [action.payload.generationId]: {
          uuid: action.payload.generationId,
          imageCount: action.payload.imageCount,
          status: GenerationStatus.Processing,
        },
      },
      restrictions: {
        ...action.payload.restrictions,
      },
    }),
    [`${generatedDeviationsLoaded}`]: (
      state,
      action: ReturnType<typeof generatedDeviationsLoaded>
    ) => ({
      ...state,
      generationJobs: omit(state.generationJobs, action.payload.generationId),
    }),
    [`${dreamupGenerationFailed}`]: (
      state,
      action: ReturnType<typeof dreamupGenerationFailed>
    ) => ({
      ...state,
      generationJobs: {
        ...state.generationJobs,
        [action.payload.generationId]: {
          ...state.generationJobs[action.payload.generationId],
          status: GenerationStatus.Failure,
          errorCode: action.payload.errorCode,
        },
      },
      restrictions: {
        ...state.restrictions,
        freePromptsRemaining: action.payload.freePromptsRemaining,
      },
    }),
    [`${toggleBurgerMenu}`]: state => ({
      ...state,
      isBurgerMenuOpen: !state.isBurgerMenuOpen,
    }),
    [`${closeBurgerMenu}`]: state => ({
      ...state,
      isBurgerMenuOpen: false,
    }),
    [`${showStartingImageSelector}`]: state => ({
      ...state,
      isStartingImageSelectorOpen: true,
    }),
    [`${hideStartingImageSelector}`]: state => ({
      ...state,
      isStartingImageSelectorOpen: false,
    }),
    [`${extendedDeviationRequestSucceeded}`]: (
      state,
      action: ReturnType<typeof extendedDeviationRequestSucceeded>
    ) => ({
      ...state,
      cachedExtendedDeviations: {
        ...state.cachedExtendedDeviations,
        [action.payload.deviation.deviationId]: action.payload.deviation,
      },
    }),
  },
  {
    currentSubmission: {
      status: SubmissionStatus.Idle,
    },
    formValues: defaultFormValues,
    isPromptSettingsPanelVisible: false,
    shouldPullFormValuesFromLocalStorage: false,
    generationJobs: {},
    cachedExtendedDeviations: {},
    restrictions: {
      // Mostly gibberish defaults so that types are easier to work with. This should get overridden by
      // the initializeDreamup action. Erring toward restrictive defaults as a safety measure.
      canUseFreePrompt: false,
      canUsePaidPrompt: false,
      freePromptsAllowed: 0,
      freePromptsRemaining: 0,
      imagesPerPrompt: 0,
      promptPointsPrice: 40,
      queuePriority: 'low',
    },
    pointsPackages: [],
    isBurgerMenuOpen: false,
    isStartingImageSelectorOpen: false,
  }
);

export default {
  [reduxKey]: reducers,
};
