import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import DreamupStar from './DreamupStar';
import s from './ArtistControlSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const ArtistControlSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Section className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['left']}>
        <Heading className={s['heading']}>
          {t('dreamupAnon.artistControl.heading')}
        </Heading>
        <ul className={s['list']}>
          <li className={s['list-entry']}>
            <DreamupStar />
            {t('dreamupAnon.artistControl.bullet1')}
          </li>
          <li className={s['list-entry']}>
            <DreamupStar />
            {t('dreamupAnon.artistControl.bullet2')}
          </li>
        </ul>
        <BrandedButton size="large" variant="dreamup-purple" href="#">
          {t('dreamupAnon.artistControl.button.label')}
        </BrandedButton>
      </div>
      <div className={s['image-wrapper']}>
        <img
          className={s['image']}
          alt=""
          src="https://st.deviantart.net/eclipse/dreamup/anon-landing/dreamupanon-aiusage-img.png"
        />
      </div>
    </Section>
  );
};

ArtistControlSection.displayName = 'ArtistControlSection';

export default ArtistControlSection;
