import React from 'react';

interface Props {
  className?: string;
}

const OldDreamupLogo: React.FC<Props> = ({ className }) => {
  return (
    <a href="https://www.deviantart.com/dreamupzone">
      <img
        className={className}
        src="https://st.deviantart.net/dreamup/old-du-light.png"
        alt="dreamup logo"
      />
    </a>
  );
};
OldDreamupLogo.displayName = 'OldDreamupLogo';

export default OldDreamupLogo;
