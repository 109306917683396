import React from 'react';
import { useTranslation } from 'react-i18next';
import type { PapiDeviation } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import {
  BiContextProvidedValue,
  BiData,
  type DreamupSubmitAsDeviationBiEvent,
} from '@wix/da-bi/pkg/events';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import UploadIcon from '@wix/da-ds/pkg/Icons/24x24/Upload';
import { getDreamupPrompt } from '@wix/da-shared-react/pkg/types/deviation/getters';

interface Props {
  deviation: PapiDeviation;
}

const SubmitButton: React.FC<Props> = ({ deviation }) => {
  const { t } = useTranslation();
  const prompt = getDreamupPrompt(deviation) ?? '';

  return (
    <BrandedButton
      size="large"
      variant="brand-green"
      icon={UploadIcon}
      href={Url.submissionLinkWithId(deviation.deviationId)}
      biData={BiData<DreamupSubmitAsDeviationBiEvent>({
        evid: 910,
        sectionname: BiContextProvidedValue,
        deviationid: deviation.deviationId,
        prompt,
      })}
    >
      {t('dreamup.modal.promptInfo.submitButton.label')}
    </BrandedButton>
  );
};

SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
