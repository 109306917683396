import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { getPromptStrength } from '../../../../../redux/dreamup/selectors';
import { changePromptStrength } from '../../../../../redux/dreamup/actions';
import PanelInputField from '../PanelInputField';
import RangeSlider from '../RangeSlider';
import LabelText from '../LabelText';

interface Props {}

const PromptStrengthSliderField: React.FC<Props> = () => {
  const { t } = useTranslation();
  const strength = useSelector(getPromptStrength);
  const dispatchChangePromptStrength = useAction(changePromptStrength);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      dispatchChangePromptStrength(parseInt(e.target.value));
    },
    [dispatchChangePromptStrength]
  );

  return (
    <PanelInputField
      rootTag="label"
      label={
        <LabelText>
          {t('dreamup.header.settingsPanel.promptStrength.label')}
        </LabelText>
      }
      input={
        <RangeSlider
          name="promptStrength"
          value={strength}
          onChange={handleChange}
          min={0}
          max={20}
          step={1}
        />
      }
    />
  );
};

PromptStrengthSliderField.displayName = 'PromptStrengthSliderField';

export default PromptStrengthSliderField;
