import React, { useCallback, useRef } from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import {
  ThemeOptions,
  ThemeOverride,
} from '@wix/da-react-context/pkg/ThemeContext';
import {
  getCurrentSubmission,
  getVariationTarget,
} from '../../../../../../redux/dreamup/selectors';
import { FormAction } from '../../../usePromptFormAction';
import StartingImageButton from '../../lego/StartingImageButton';
import PromptTextArea from '../../lego/PromptTextArea';
import PromptSubmitButton from '../../lego/PromptSubmitButton';
import VariationTag from '../../lego/VariationTag';
import SettingsPanel from '../../../SettingsPanel';
import ErrorArea from '../../lego/ErrorArea';

import { SubmissionStatus } from '../../../../../../redux/dreamup/types';
import s from './PromptInputDesktop.scss';

interface Props {
  formAction: FormAction;
  className?: string;
}

const PromptInputDesktop: React.FC<Props> = ({ formAction, className }) => {
  const variationTarget = useSelector(getVariationTarget);
  const currentSubmission = useSelector(getCurrentSubmission);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
    useCallback(
      e => {
        if (e.key === 'Enter') {
          if (submitButtonRef.current) {
            submitButtonRef.current.click();
          }
          e.preventDefault();
        }
      },
      [submitButtonRef]
    );

  return (
    <div className={c(s['root'], className)}>
      <div className={c(s['input-row'], className)}>
        {variationTarget ? (
          <VariationTag deviation={variationTarget} />
        ) : (
          <StartingImageButton key="starting-image" />
        )}
        <div className={s['text-area-and-error']}>
          <ThemeOverride theme={ThemeOptions.LIGHT}>
            <PromptTextArea formAction={formAction} onKeyDown={handleKeyDown} />
          </ThemeOverride>

          <ErrorArea
            className={c(
              // Because the error area is always mounted for a11y reasons, we have to make sure the error is actually
              // present before we start adding spacing between it and the text area.
              currentSubmission.status === SubmissionStatus.Error &&
                s['error-area-top-margin']
            )}
          />
        </div>

        <PromptSubmitButton formAction={formAction} ref={submitButtonRef} />
      </div>

      <SettingsPanel />
    </div>
  );
};

PromptInputDesktop.displayName = 'PromptInputFieldWithButton';

export default PromptInputDesktop;
