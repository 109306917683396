import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { PapiDeviation } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import MessageModal, {
  type MessageModalProps,
} from '@wix/da-ds/pkg/modals/dsPresets/MessageModal';
import { CancelButton } from '@wix/da-ds/pkg/modals/dsPresets/MessageModal/buttons';
import { Leave as LeaveImage } from '@wix/da-ds/pkg/Icons/Confirmations';
import { popAllModals } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import PromptCountAndPointsBalance from './PromptCountAndPointsBalance';
import { useUpscaleConfirmationAction } from './useUpscaleConfirmationAction';
import UpscaleConfirmationButton from './UpscaleConfirmationButton';
import s from './UpscaleConfirmationModal.scss';

interface Props
  extends Omit<
    MessageModalProps,
    | 'title'
    | 'message'
    | 'image'
    | 'footerButtons'
    | 'variant'
    | 'doNotShowAgainText'
    | 'onDoNotShowAgainChange'
    | 'isOpen'
  > {
  deviation: PapiDeviation;
}

const UpscaleConfirmationModal: React.FC<Props> = ({
  deviation,
  onRequestClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const upscaleConfirmationAction = useUpscaleConfirmationAction();
  const dispatchPopAllModals = useAction(popAllModals);

  return (
    <MessageModal
      isOpen
      onRequestClose={onRequestClose}
      className={s['root']}
      image={<LeaveImage />}
      variant={isMobile ? 'vertical' : 'horizontal'}
      title={t('dreamup.modal.upscaleConfirmation.title')}
      message={
        <div className={s['content-root']}>
          {t('dreamup.modal.upscaleConfirmation.message')}
          {'\n\n'}
          <PromptCountAndPointsBalance
            confirmationAction={upscaleConfirmationAction}
          />
        </div>
      }
      footerButtons={[
        <CancelButton key="cancel" onClick={onRequestClose}>
          {t('dreamup.modal.upscaleConfirmation.cancelButton')}
        </CancelButton>,
        <UpscaleConfirmationButton
          key="confirm"
          confirmationAction={upscaleConfirmationAction}
          deviation={deviation}
          // This modal can be stacked on top of the `DreamupModals.PROMPT_INFO` modal, so
          // we pop all of them on confirmation to be sure
          closeCallback={dispatchPopAllModals}
        />,
      ]}
      {...rest}
    />
  );
};

UpscaleConfirmationModal.displayName = 'UpscaleConfirmationModal';

export default UpscaleConfirmationModal;
