import React from 'react';
import c from 'classnames';
import { Trans } from 'react-i18next';
import HoverableWrapper from '@wix/da-ds/pkg/thumbs/OverlayHelpers/HoverableWrapper';
import Loader from '@wix/da-ds/pkg/Loader';
import s from './DreamupPlaceholderThumb.scss';

interface Props {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  className?: string;
}

const DreamupPlaceholderThumb: React.FC<Props> = ({
  className,
  width,
  height,
  style = {},
}) => (
  <HoverableWrapper
    style={{ width, height, ...style }}
    className={c(s['root'], className)}
  >
    <Loader size="big" />
    <span className={s['loader-text']}>
      <Trans i18nKey="dreamup.loader">
        Loading time: 60 seconds<br />
        (roughly 30 llama hops)
      </Trans>
    </span>
  </HoverableWrapper>
);


DreamupPlaceholderThumb.displayName = 'DreamupPlaceholderThumb';

export default DreamupPlaceholderThumb;
