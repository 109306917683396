import React, { LabelHTMLAttributes } from 'react';
import s from './PanelInputField.scss';

export interface Props extends LabelHTMLAttributes<any> {
  /**
   * Our input's label.
   *
   * This field requires a single root element for compatibility with grid styling.
   */
  label: React.ReactNode;

  /**
   * Our input element.
   *
   * This field requires a single root element for compatibility with grid styling.
   */
  input: React.ReactNode;
  rootTag?: keyof JSX.IntrinsicElements;
}

const PanelInputField: React.FC<Props> = ({
  label,
  input,
  rootTag: Tag = 'div',
  ...rest
}) => {
  return (
    <Tag className={s['root']} {...rest}>
      {label}
      {input}
    </Tag>
  );
};

PanelInputField.displayName = 'PanelInputField';

export default PanelInputField;
