import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import Modal, { type ModalProps } from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import { useFirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/hooks';
import { FirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags';
import s from './PromptGuidelinesModal.scss';

interface Props
  extends Omit<
    ModalProps,
    | 'isOpen'
    | 'heading'
    | 'children'
    | 'showHeaderBorder'
    | 'showFooterBorder'
    | 'footerRight'
  > {
  onConfirm?: () => void;
}

const PromptGuidelinesModal: React.FC<Props> = ({
  onConfirm,
  onRequestClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const { dropFlag } = useFirstTimeFlag(
    FirstTimeFlag.IsDreamupPromptGuidelinesNotSeen
  );

  const handleConfirmClick = useCallback(
    e => {
      dropFlag();
      onConfirm && onConfirm();
      onRequestClose && onRequestClose(e);
    },
    [dropFlag, onRequestClose, onConfirm]
  );

  return (
    <Modal
      isOpen
      showFooterBorder
      showHeaderBorder
      className={s['root']}
      heading={t('dreamup.modal.promptGuidelines.heading')}
      footerRight={[
        <TextButton
          key="cancel"
          size="large"
          variant="main"
          onClick={onRequestClose}
        >
          {t('dreamup.modal.promptGuidelines.cancelButton.label')}
        </TextButton>,
        <BrandedButton
          key="confirm"
          size={'large'}
          variant={'brand-green'}
          onClick={handleConfirmClick}
        >
          {t('dreamup.modal.promptGuidelines.confirmButton.label')}
        </BrandedButton>,
      ]}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <div className={s['description']}>
        <Trans
          i18nKey="dreamup.modal.promptGuidelines.description"
          components={{
            p: <p />,
            ul: <ul />,
            li: <li />,
            b: <b />,
            /* eslint-disable jsx-a11y/anchor-has-content */
            'dreamup-policy-link': (
              <a href={URLS.DREAMUP_POLICY} target="_blank" rel="noreferrer" />
            ),
            'terms-of-service-link': (
              <a href={URLS.TOS} target="_blank" rel="noreferrer" />
            ),
            /* eslint-enable jsx-a11y/anchor-has-content */
          }}
        />
      </div>
    </Modal>
  );
};

PromptGuidelinesModal.displayName = 'PromptGuidelinesModal';

export default PromptGuidelinesModal;
